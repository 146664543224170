import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Typography,
    Divider,
    List,
    Button,
    Segmented,
    Flex,
    Pagination,
    Spin,
    Empty,
    Select,
    DatePicker as AntdDatePicker,
    Tooltip,
    Modal,
    Drawer,
} from 'antd'
import VirtualList from 'rc-virtual-list'
import styles from './index.less'
// import { ReactComponent as ItemLinkIcon } from '../../Assest/Svg/MissingInfoTittleIcon.svg'
import { ReactComponent as DashboardTodoClock } from '../../Assest/Svg/DashboardToDoClock.svg'
import { ReactComponent as DashboardTodoEmail } from '../../Assest/Svg/DashboardTodoEmail.svg'
import { ReactComponent as DashboardTodoMessage } from '../../Assest/Svg/DashboardTodoMessage.svg'
import { ReactComponent as DashboardTodoCall } from '../../Assest/Svg/DashboardTodoCall.svg'
import { ReactComponent as DashboardTodoDocument } from '../../Assest/Svg/DashboardTodoDocument.svg'
import { ReactComponent as DashboardTodoTransfer } from '../../Assest/Svg/DashboardTodoTransfer.svg'
import { ReactComponent as DashboardTodoMoney } from '../../Assest/Svg/DashboardTodoMoney.svg'
import { ReactComponent as DashboardTodoChat } from '../../Assest/Svg/DashboardTodoChat.svg'
import { ReactComponent as DashboardTodoDelete } from '../../Assest/Svg/DashboardTodoDelete.svg'
import type { ToFollowProps } from '../../Types/Dashboard'
import axios from '../../Config/AxiosConfig'
import { ErrorMessage } from '../../Utils/Notification'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import CommunicationHistory from '../ThingToDoViewAll/CommunicationModel/CommunicationHistory'
import ToDoPhoneCallModel from '../ThingToDoViewAll/CommunicationModel/phoneCallModel'
import SMSModelPage from '../ThingToDoViewAll/CommunicationModel/smsModel'
import StaffCommunication from '../ThingToDoViewAll/CommunicationModel/staffCommunication'
import SwitchUserPage from '../ThingToDoViewAll/CommunicationModel/switchUserModel'
import ServiceProcessHistory from '../ThingToDoViewAll/ProcessModel/serviceProcessHistoryModel'
import MailTempalteModel from '../ThingToDoViewAll/CommunicationModel/mailModel'
import InvoiceModel from '../ThingToDoViewAll/ProcessModel/invoiceModel'
import VoidThisProcess from '../ThingToDoViewAll/ProcessModel/voidthisProcessModel'
import ActivePremission from '../../Utils/premissins'
import { PermissionType } from '../../Types/Enum/PermissionType'
import { useDispatch } from 'react-redux'
import { servicedataId } from '../../Reducer/DashboardFlagReducer'
const { RangePicker } = AntdDatePicker
const { Text } = Typography

interface HeaderDataType {
    label: string
    color: string
    count: string
    stdId: number
}

interface StandardType {
    stdId: number
    stdName: string
}

interface GetServiceFollowUpThings {
    serviceProcessId: number
    serviceId: number
    serviceName: string
    businessTypeId: number
    referenceId: number
    year: number
    periodStartDate: string
    periodEndDate: string
    currentStatus: string
    dueDays: number
    assignBy: string
    assignTo: string
    assignDate: string
    dueDate: string
    completeDate: string
    businessType: string
    clientName: string
    clientNumber: string
    serviceProgressStatus: number
    actionName: string
    additionalDataOne: string
    additionalDataTwo: string
    additionalDataThree: string
    additionalDataFour: string
    additionalDataFive: string
    lastCommunicationStatus: string
    processDueDate: string
    assignDueDays: number
    dueDateDescription: string
    dueDateProgressStatus: number
    isNewChatExist: number
    signableUrl: string
    companyStatus: string
}

const ToFollow = ({
    clientthingstofollowInProgressList,
    clientthingstofollowOverDueList,
    taskthingstofollowInProgressList,
    taskthingstofollowOverDueList,
}: ToFollowProps): React.ReactElement => {
    const [data, setData] = useState<GetServiceFollowUpThings[]>([])
    const [headerData, setHeaderData] = useState<HeaderDataType[]>([])
    const [selectedTab, setTab] = useState<number>(0)
    const [flag, setFlag] = useState(0)
    const [stdId, setStdId] = useState(0)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [noOfRecords, setNoOfRecords] = useState(0)
    const [dropdownItem, setDropDownItem] = useState(0)
    const navigate = useNavigate()
    const [Historymodel, setHistorymodel] = useState(false)
    const [comHistorymodel, setCommunicationHistorymodel] = useState(false)
    const [serviceProcessId, setServiceProcessId] = useState<number>(0)
    const [selectedButtonId, setSelectedButtonId] = useState<number | null>(
        null
    )
    const [PhoneCallModel, setPhoneCallmodel] = useState(false)
    const [SMSModel, setSMSmodel] = useState(false)
    const [mailmodel, setMailmodel] = useState(false)
    const [switchUserModel, setSwitchUserModel] = useState(false)
    const [remarksModel, setRemarksModel] = useState(false)
    const [open, setOpen] = useState(false)
    const [clientName, setClientName] = useState<string>('')
    const [referenceId, serRefernceId] = useState(0)
    const [businessTypeId, serBusineType] = useState(0)
    const [voidThisModel, setVoidThisModel] = useState(false)
    const dispatch = useDispatch()
    const onChangeDropDown = (data: number): void => {
        setDropDownItem(data)
    }

    const onChangeTab = (index: number): void => {
        setTab(index)
        setFlag(index)
    }
    const dateFormat = 'YYYY/MM/DD'
    const nowDate = dayjs(new Date().toISOString(), dateFormat)
    const currDate = new Date()
    currDate.setMonth(currDate.getMonth() + 1)

    const nextDate = dayjs(currDate.toISOString(), dateFormat)

    const defaultDateRange: string[] = [
        nowDate.toISOString(),
        nextDate.toISOString(),
    ]
    const [dateRange, setDateRange] = useState<string[]>(defaultDateRange)
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString !== null ? JSON.parse(tokenDetailsString) : null
    const headerColor = (label: string): string => {
        switch (label) {
            case 'AA':
                return '#FFC061'
            case 'VAT':
                return '#695EEF'
            case 'PAYE':
                return '#73DCE9'
            case 'CS':
                return '#11D1B7'
            case 'Pension':
                return '#4ECB71'
            case 'Adhoc':
                return '#D99BFF'
            default:
                return '#FFC061'
        }
    }

    const filterService = (
        stdId: number
    ): { id: number; isPermission: boolean } => {
        switch (stdId) {
            case 1:
                return {
                    id: stdId,
                    isPermission: ActivePremission('4000', PermissionType.VIEW),
                }
            case 3:
                return {
                    id: stdId,
                    isPermission: ActivePremission('4001', PermissionType.VIEW),
                }
            case 10:
                return {
                    id: stdId,
                    isPermission: ActivePremission('4002', PermissionType.VIEW),
                }
            case 9:
                return {
                    id: stdId,
                    isPermission: ActivePremission('4003', PermissionType.VIEW),
                }
            case 4:
                return {
                    id: stdId,
                    isPermission: ActivePremission('4005', PermissionType.VIEW),
                }
            case 100:
                return {
                    id: stdId,
                    isPermission: ActivePremission('4006', PermissionType.VIEW),
                }
            default:
                return {
                    id: 0,
                    isPermission: false,
                }
        }
    }
    const getStandardListing = async (): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListing/18`)
            .then((response: any) => {
                if (response.status === 200) {
                    const newData = response?.data?.data?.map(
                        (item: StandardType) => {
                            return {
                                label: item.stdName,
                                color: headerColor(item.stdName),
                                count: 0,
                                stdId: item.stdId,
                            }
                        }
                    )

                    const filterData = newData
                        ?.filter((x: StandardType) => {
                            const filter = filterService(x?.stdId)
                            if (filter?.id !== 0 && filter?.isPermission) {
                                return x
                            } else {
                                return undefined
                            }
                        })
                        ?.filter((y: StandardType) => y !== undefined)
                    setStdId(filterData[0].stdId)
                    setHeaderData(filterData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch((error: any) => {
                setLoading(false)
                ErrorMessage(error)
            })
    }

    useEffect(() => {
        void getStandardListing()
    }, [])

    const setTabData = async (): Promise<void> => {
        setLoading(true)
        await axios
            .post(`api/Dashboard/GetServiceFollowUpThings`, {
                searchString: '',
                pageNo: pageNumber,
                pageSize: 10,
                flagId: 2,
                serviceId: stdId,
                statusId: selectedTab,
                clientCategoryId: 0,
                clientTypeId: 0,
                fromDate: '',
                toDate: '',
                nextStatmentFromDate: stdId === 9 ? dateRange[0] : '',
                nextStatmentToDate: stdId === 9 ? dateRange[1] : '',
                assignedBy: tokenDetails.userId ?? 0,
                assignedTo: 0,
                payeFrequency: dropdownItem,
            })
            .then((response: any) => {
                if (response.status === 200) {
                    setNoOfRecords(response?.data?.noOfRecords)
                    const newData: GetServiceFollowUpThings[] =
                        response?.data?.data
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch((error: any) => {
                setLoading(false)
                ErrorMessage(error)
            })
    }

    useEffect(() => {
        setPageNumber(1)

        void setTabData()
    }, [flag, stdId, dropdownItem, dateRange])

    useEffect(() => {
        if (pageNumber > 1) {
            void setTabData()
        }
    }, [pageNumber])

    useEffect(() => {
        if (stdId === 3 || stdId === 4) {
            setDropDownItem(0)
            void setTabData()
        }
    }, [stdId])
    const options = [
        // {
        //     value: 0,
        //     icon: (
        //         <Flex
        //             justify="center"
        //             align="center"
        //             style={{ padding: '5px 5px', userSelect: 'none' }}
        //         >
        //             <Text>All</Text>
        //         </Flex>
        //     ),
        // },
        ...headerData.map((value: HeaderDataType) => {
            return {
                value: value.stdId,
                icon: (
                    <Flex
                        vertical
                        align="center"
                        justify="center"
                        style={{ padding: '8px 20px', userSelect: 'none' }}
                    >
                        {/* <div
                            style={{
                                backgroundColor: 'rgb(227, 239, 250)',
                                color: '#4eaaff',
                                borderRadius: '10px',
                                padding: '2px 5px',
                                fontSize: '10px',
                                fontWeight: 600,
                                lineHeight: '14px',
                            }}
                        >
                            {value.count}
                        </div> */}
                        <Flex justify="center" align="center" gap={4}>
                            <div
                                style={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '999px',
                                    backgroundColor: value.color,
                                }}
                            ></div>
                            <Text>{value.label}</Text>
                        </Flex>
                    </Flex>
                ),
            }
        }),
    ]

    const onHistoryModelOpen = (serviceprocesId: number): void => {
        setHistorymodel(!Historymodel)
        setServiceProcessId(serviceprocesId)
    }

    const changeHistoryModalStatus = (): void => {
        setHistorymodel(!Historymodel)
    }

    const openHistoryModel = (id: number): void => {
        setServiceProcessId(id)
        setCommunicationHistorymodel(!comHistorymodel)
    }

    const changeCommunictionHistoryModalStatus = (): void => {
        setSelectedButtonId(null)
        setCommunicationHistorymodel(!comHistorymodel)
    }
    const openPhoneCallModel = (id: number, businessTypeId: number): void => {
        setServiceProcessId(id)
        serBusineType(businessTypeId)
        setPhoneCallmodel(!PhoneCallModel)
    }

    const changePhoneCallModalStatus = (): void => {
        setPhoneCallmodel(!PhoneCallModel)
    }

    const openSMSModel = (id: number): void => {
        setServiceProcessId(id)
        setSMSmodel(!SMSModel)
    }

    const changeSMSModalStatus = (): void => {
        setSMSmodel(!SMSModel)
    }

    const openMailModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setMailmodel(!mailmodel)
    }

    const changeMailModalStatus = (): void => {
        setMailmodel(!mailmodel)
    }

    const openSwitchUserModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setSwitchUserModel(!switchUserModel)
    }

    const changeSwitchUserModalStatus = (): void => {
        setSwitchUserModel(!switchUserModel)
    }

    const openRemarksModel = (
        serviceProcessId: number,
        refernceId: number,
        businessTypeId: number
    ): void => {
        setServiceProcessId(serviceProcessId)
        serRefernceId(refernceId)
        serBusineType(businessTypeId)
        setRemarksModel(!remarksModel)
    }

    const changeRemarksModalStatus = (): void => {
        setRemarksModel(!remarksModel)
    }

    const showDrawer = (id: number, name: string): void => {
        setServiceProcessId(id)
        setClientName(name)
        setOpen(true)
    }

    const onClose = (): void => {
        setOpen(false)
    }

    const openViodModel = (serviceProcessId: number): void => {
        setServiceProcessId(serviceProcessId)
        setVoidThisModel(!voidThisModel)
    }

    const changeVoidModalStatus = (): void => {
        setVoidThisModel(!voidThisModel)
    }
    return (
        <div className={styles.marginRight10}>
            <Card className={`${styles.card}`}>
                <div className={styles.cardContent}>
                    <Row>
                        <Col span={12}>
                            <div>
                                <div className={styles.mobileRequest__tittle}>
                                    To Follow
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className={styles.todo__tab__one}>
                        <Segmented
                            style={{ overflow: 'hidden', width: '100%' }}
                            options={options}
                            value={stdId}
                            onChange={(value) => {
                                setStdId(Number(value))
                            }}
                        />
                    </Row>
                    <Divider style={{ margin: '10px 0px' }} />
                    <Row>
                        {(stdId === 3 || stdId === 4) && (
                            <Col
                                span={24}
                                className={styles.displayflex}
                                style={{
                                    placeContent: 'end',
                                    paddingBottom: '10px',
                                }}
                            >
                                <Select
                                    value={dropdownItem}
                                    bordered={false}
                                    className={styles.toFollow__select}
                                    onChange={onChangeDropDown}
                                    options={[
                                        { value: 0, label: 'All' },
                                        { value: 1, label: 'Weekly' },
                                        { value: 2, label: 'Bi Weekly' },
                                        { value: 3, label: 'Four Weekly' },
                                        { value: 4, label: 'Monthly' },
                                    ]}
                                />
                            </Col>
                        )}

                        {stdId === 9 && (
                            <Col
                                span={24}
                                style={{
                                    display: 'flex',
                                    placeContent: 'end',
                                    paddingBottom: '10px',
                                }}
                            >
                                <RangePicker
                                    defaultValue={[nowDate, nextDate]}
                                    format={dateFormat}
                                    onChange={(dates) => {
                                        setDateRange(
                                            dates !== null &&
                                                dates !== undefined
                                                ? dates?.map((date) =>
                                                      dayjs(date).format(
                                                          dateFormat
                                                      )
                                                  )
                                                : []
                                        )
                                    }}
                                />
                            </Col>
                        )}
                        <Col
                            span={6}
                            onClick={() => {
                                onChangeTab(0)
                            }}
                        >
                            <div
                                className={
                                    selectedTab === 0
                                        ? styles.todo__tab__two__main__active
                                        : styles.todo__tab__two__main__inactive
                                }
                            >
                                In-progress
                            </div>
                        </Col>
                        <Col
                            span={6}
                            onClick={() => {
                                onChangeTab(1)
                            }}
                        >
                            <div
                                className={
                                    selectedTab === 1
                                        ? styles.todo__tab__two__main__active
                                        : styles.todo__tab__two__main__inactive
                                }
                            >
                                Overdue
                            </div>
                        </Col>
                        <Col
                            span={6}
                            onClick={() => {
                                onChangeTab(2)
                            }}
                        >
                            <div
                                className={
                                    selectedTab === 2
                                        ? styles.todo__tab__two__main__active
                                        : styles.todo__tab__two__main__inactive
                                }
                            >
                                Completed
                            </div>
                        </Col>

                        <Col span={6}>
                            <div
                                className={styles.todo__tab__two__main__right}
                                onClick={() => {
                                    const mode = 2
                                    navigate(`/thingto-do`, {
                                        state: { mode },
                                    })
                                }}
                            >
                                View All
                            </div>
                        </Col>
                    </Row>
                    {isLoading ? (
                        <Flex
                            align="center"
                            justify="center"
                            style={{ padding: '20px 0px' }}
                        >
                            <Spin />
                        </Flex>
                    ) : (
                        <>
                            {data.length > 0 ? (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <List>
                                                <VirtualList
                                                    data={data}
                                                    height={600}
                                                    itemKey="serviceProcessId"
                                                    className={
                                                        styles.todo__list__main
                                                    }
                                                >
                                                    {(
                                                        item: GetServiceFollowUpThings,
                                                        index: number
                                                    ) => (
                                                        <List.Item key={index}>
                                                            <Row
                                                                className={
                                                                    styles.todo__list__main__item__main
                                                                }
                                                            >
                                                                <Col span={19}>
                                                                    <div
                                                                        className={
                                                                            styles.todo__list__main__item__main__tittle
                                                                        }
                                                                    >
                                                                        {
                                                                            item.clientName
                                                                        }
                                                                        &nbsp;&nbsp;
                                                                        {/* <ItemLinkIcon /> */}
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            styles.todo__list__second__item__tittle
                                                                        }
                                                                    >
                                                                        Next
                                                                        accounts
                                                                        made up
                                                                        to{' '}
                                                                        {
                                                                            item.periodStartDate
                                                                        }{' '}
                                                                        due by{' '}
                                                                        {
                                                                            item.periodEndDate
                                                                        }
                                                                    </div>
                                                                    <Row>
                                                                        <Col
                                                                            span={
                                                                                6
                                                                            }
                                                                            className={
                                                                                styles.todo__list__third__item__first__tittle
                                                                            }
                                                                        >
                                                                            Assign
                                                                            by:{' '}
                                                                            {
                                                                                item.assignBy
                                                                            }
                                                                        </Col>
                                                                        <Col
                                                                            span={
                                                                                18
                                                                            }
                                                                            className={
                                                                                styles.todo__list__third__item__second__tittle
                                                                            }
                                                                        >
                                                                            Comunicate
                                                                            via:{' '}
                                                                            <Tooltip
                                                                                title={
                                                                                    'Communication History'
                                                                                }
                                                                            >
                                                                                &nbsp;&nbsp;
                                                                                <DashboardTodoClock
                                                                                    onClick={() => {
                                                                                        openHistoryModel(
                                                                                            item.serviceProcessId
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                title={
                                                                                    'Send Email'
                                                                                }
                                                                            >
                                                                                &nbsp;&nbsp;
                                                                                <DashboardTodoEmail
                                                                                    onClick={() => {
                                                                                        openMailModel(
                                                                                            item.serviceProcessId
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                title={
                                                                                    'Save SMS'
                                                                                }
                                                                            >
                                                                                &nbsp;&nbsp;
                                                                                <DashboardTodoMessage
                                                                                    onClick={() => {
                                                                                        openSMSModel(
                                                                                            item.serviceProcessId
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip
                                                                                title={
                                                                                    'Save PhoneCall'
                                                                                }
                                                                            >
                                                                                &nbsp;&nbsp;
                                                                                <DashboardTodoCall
                                                                                    onClick={() => {
                                                                                        openPhoneCallModel(
                                                                                            item.serviceProcessId,
                                                                                            item.businessTypeId
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col
                                                                    span={5}
                                                                    className={
                                                                        styles.todo__last__item__main
                                                                    }
                                                                >
                                                                    <Tooltip
                                                                        title={
                                                                            'Start Process'
                                                                        }
                                                                    >
                                                                        <Button
                                                                            size="small"
                                                                            type="primary"
                                                                            className={
                                                                                styles.todo__last__item__button
                                                                            }
                                                                            onClick={() => {
                                                                                const serviceprocessId =
                                                                                    item.serviceProcessId
                                                                                const serviceId =
                                                                                    stdId
                                                                                dispatch(
                                                                                    servicedataId(
                                                                                        {
                                                                                            serviceProcesssId:
                                                                                                serviceprocessId,
                                                                                            serviceId:
                                                                                                serviceId ??
                                                                                                0,
                                                                                        }
                                                                                    )
                                                                                )
                                                                                navigate(
                                                                                    `/thingto-do/service-process`
                                                                                )
                                                                            }}
                                                                        >
                                                                            Start
                                                                            Process
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <div
                                                                        className={
                                                                            styles.todo__last__item__icons
                                                                        }
                                                                    >
                                                                        {' '}
                                                                        <Tooltip
                                                                            title={
                                                                                'Service Process History'
                                                                            }
                                                                        >
                                                                            <DashboardTodoDocument
                                                                                onClick={() => {
                                                                                    onHistoryModelOpen(
                                                                                        item.serviceProcessId
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                'Switch User'
                                                                            }
                                                                        >
                                                                            &nbsp;&nbsp;
                                                                            <DashboardTodoTransfer
                                                                                onClick={() => {
                                                                                    openSwitchUserModel(
                                                                                        item.serviceProcessId
                                                                                    )
                                                                                }}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                'Invoice'
                                                                            }
                                                                        >
                                                                            <DashboardTodoMoney
                                                                                onClick={() => {
                                                                                    openRemarksModel(
                                                                                        item.serviceProcessId,
                                                                                        item.referenceId,
                                                                                        item.businessTypeId
                                                                                    )
                                                                                }}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                'Staff Communication'
                                                                            }
                                                                        >
                                                                            <DashboardTodoChat
                                                                                onClick={() => {
                                                                                    showDrawer(
                                                                                        item.serviceProcessId,
                                                                                        item.clientName
                                                                                    )
                                                                                }}
                                                                            />
                                                                            &nbsp;&nbsp;
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                'Revert All'
                                                                            }
                                                                        >
                                                                            <DashboardTodoDelete
                                                                                onClick={() => {
                                                                                    openViodModel(
                                                                                        item.serviceProcessId
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </List.Item>
                                                    )}
                                                </VirtualList>
                                            </List>
                                        </Col>
                                    </Row>

                                    <Row
                                        style={{
                                            width: '100%',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Col
                                            span={24}
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'stretch',
                                            }}
                                        >
                                            <Pagination
                                                defaultCurrent={pageNumber}
                                                total={noOfRecords}
                                                onChange={(e) => {
                                                    setPageNumber(e)
                                                }}
                                                showSizeChanger={false}
                                                showQuickJumper={false}
                                                size="small"
                                            />
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Empty
                                    style={{ padding: '40px 0px' }}
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        <span>No tasks to follow up.</span>
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            </Card>
            <Modal
                footer={null}
                onCancel={changeHistoryModalStatus}
                width={'60%'}
                title={'Service Process History'}
                open={Historymodel}
            >
                <ServiceProcessHistory serviceprocessId={serviceProcessId} />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCommunictionHistoryModalStatus}
                width={'50%'}
                title={'Commication History'}
                open={comHistorymodel}
            >
                <CommunicationHistory
                    serviceProcessId={serviceProcessId}
                    seletedButton={selectedButtonId}
                    setButtonID={setSelectedButtonId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changePhoneCallModalStatus}
                width={'35%'}
                title={'Phone Call'}
                open={PhoneCallModel}
            >
                <ToDoPhoneCallModel
                    serviceProcessId={serviceProcessId}
                    changePhoneCallModalStatus={changePhoneCallModalStatus}
                    businessTypeId={businessTypeId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeSMSModalStatus}
                width={'35%'}
                title={'Send SMS'}
                open={SMSModel}
            >
                <SMSModelPage
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeSMSModalStatus}
                    serviceId={stdId}
                />
            </Modal>

            <Modal
                footer={null}
                onCancel={changeMailModalStatus}
                width={'35%'}
                title={'Send Email'}
                open={mailmodel}
            >
                <MailTempalteModel
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeMailModalStatus}
                    serviceId={stdId}
                    emailPageShow={3}
                />
            </Modal>
            <Drawer
                title={clientName}
                width={500}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication serviceProcessId={serviceProcessId} />
            </Drawer>
            <Modal
                footer={null}
                onCancel={changeSwitchUserModalStatus}
                width={'35%'}
                title={'Switch User'}
                open={switchUserModel}
            >
                <SwitchUserPage
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeSwitchUserModalStatus}
                    serviceId={stdId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeRemarksModalStatus}
                width={'45%'}
                title={'Invoice'}
                open={remarksModel}
            >
                <InvoiceModel
                    serviceProcessId={serviceProcessId}
                    changeModalStatus={changeRemarksModalStatus}
                    serviceId={stdId}
                    referenceId={referenceId}
                    businessType={businessTypeId}
                />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeVoidModalStatus}
                width={'35%'}
                title={'Void this process'}
                open={voidThisModel}
            >
                <VoidThisProcess
                    changeModelState={changeVoidModalStatus}
                    serviceProcessId={serviceProcessId}
                />
            </Modal>
        </div>
    )
}

export default ToFollow
