import React, { useCallback, useEffect, useState } from 'react'
import CommonButton from '../../Components/Commonbutton'
import {
    type ThingstodoStateIntrface,
    type ToDoViewAllListButtonProps,
} from '../../Types/ToDoViewAllList'
import { GetToDoListClientWise } from '../../Services/ToDoViewAllList'
import { connect, useDispatch } from 'react-redux'
import VatList from './Buttons/VatTablePage'
import AnualList from './Buttons/AnualPage'
import ConformationStatement from './Buttons/Cspage'
import PayeList from './Buttons/PayePage'
import PensionList from './Buttons/penitionPage'
import AdhocList from './Buttons/Adhocpage'

const ThingsToDoTaps = (
    props: ToDoViewAllListButtonProps
): React.ReactElement => {
    const {
        searchData,
        flagId,
        clientCategoryId,
        thingsTodoList,
        thingsTodoListCount,
        businessTypeId,
        setServiceId,
        selectedIcon,
        id,
        statusId,
    } = props
    const [activeButton, setActiveButton] = useState(1)
    const [vatButton, setVatButton] = useState(true)
    const [anualAccountButton, setAAButton] = useState(false)
    const [csButton, setCSButton] = useState(false)
    const [PayeButton, setPayeButton] = useState(false)
    const [PensionButton, setPensionButton] = useState(false)
    const [adHocButton, setAdHocButton] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [PageSize, setPagesize] = useState(10)
    const [dropdownItem, setDropdownId] = useState(0)
    const dispatch = useDispatch()
    const [searchStrings, setSearchString] = useState('')
    console.log('searchData22', searchData)
    useEffect(() => {
        setServiceId(activeButton)
    }, [activeButton])
    const getTableData = useCallback(() => {
        const bodyData = {
            searchString: searchStrings,
            pageNo: pageNumber,
            pageSize: PageSize,
            flagId,
            serviceId: activeButton,
            statusId,
            clientCategoryId,
            clientTypeId: businessTypeId ?? 0,
            fromDate: searchData.targetDate[0] ?? '',
            toDate: searchData.targetDate[1] ?? '',
            nextStatmentFromDate:
                activeButton === 9 || activeButton === 10
                    ? searchData.nextStatmentToDate[0]
                    : '',
            nextStatmentToDate:
                activeButton === 9 || activeButton === 10
                    ? searchData.nextStatmentToDate[1]
                    : '',
            assignedBy: searchData.assignedbyId ?? 0,
            assignedTo: searchData.assignedToId ?? 0,
            payeFrequency: dropdownItem,
        }

        GetToDoListClientWise(dispatch, bodyData)
        // GetToDoListTaskwise(dispatch, bodyData)
    }, [
        selectedIcon,
        flagId,
        statusId,
        activeButton,
        dropdownItem,
        businessTypeId,
        searchData,
        pageNumber,
        PageSize,
        searchStrings,
        clientCategoryId,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)
        switch (buttonName) {
            case 1:
                setVatButton(true)
                break
            case 9:
                setAAButton(true)
                break
            case 10:
                setCSButton(true)
                break
            case 3:
                setPayeButton(true)
                break
            case 4:
                setPensionButton(true)
                break
            case 100:
                setAdHocButton(true)
                break
            default:
                break
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return (
                <>
                    <VatList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        taskViewId={selectedIcon}
                        serviceId={activeButton}
                        id={id}
                        setSearchString={setSearchString}
                        statusId={statusId}
                    />
                </>
            )
        } else if (activeButton === 9) {
            return (
                <>
                    <AnualList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        taskViewId={selectedIcon}
                        serviceId={activeButton}
                        id={id}
                        setSearchString={setSearchString}
                        statusId={statusId}
                    />
                </>
            )
        }
        if (activeButton === 10) {
            return (
                <>
                    <ConformationStatement
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        id={id}
                        setSearchString={setSearchString}
                        serviceId={activeButton}
                        statusId={statusId}
                        taskViewId={selectedIcon}
                    />
                </>
            )
        }
        if (activeButton === 3) {
            return (
                <>
                    <PayeList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        setDropdownId={setDropdownId}
                        id={id}
                        setSearchString={setSearchString}
                        serviceId={activeButton}
                        statusId={statusId}
                        taskViewId={selectedIcon}
                    />
                </>
            )
        }
        if (activeButton === 4) {
            return (
                <>
                    <PensionList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        setDropdownId={setDropdownId}
                        id={id}
                        setSearchString={setSearchString}
                        serviceId={activeButton}
                        statusId={statusId}
                        taskViewId={selectedIcon}
                    />
                </>
            )
        }
        if (activeButton === 100) {
            return (
                <>
                    <AdhocList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        id={id}
                        setSearchString={setSearchString}
                        serviceId={activeButton}
                        statusId={statusId}
                        taskViewId={selectedIcon}
                    />
                </>
            )
        }
        return <></>
    }

    return (
        <>
            <CommonButton
                name="VAT"
                isActive={activeButton === 1}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(1)
                }}
            />
            <CommonButton
                name="Annual Accounts"
                isActive={activeButton === 9}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(9)
                }}
            />
            <CommonButton
                name="Conformation Statement"
                isActive={activeButton === 10}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(10)
                }}
            />
            <CommonButton
                name="PAYE"
                isActive={activeButton === 3}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(3)
                }}
            />
            <CommonButton
                name="Pension"
                isActive={activeButton === 4}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(4)
                }}
            />
            <CommonButton
                name="Ad hoc"
                isActive={activeButton === 100}
                clickAction={() => handleOtherFunction()}
                additionalOnClick={() => {
                    handleButtonClick(100)
                }}
            />
            {vatButton && activeButton === 1 && (
                <div>
                    <VatList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        taskViewId={selectedIcon}
                        serviceId={activeButton}
                        id={id}
                        setSearchString={setSearchString}
                        statusId={statusId}
                    />
                </div>
            )}
            {anualAccountButton && activeButton === 9 && (
                <div>
                    <AnualList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        taskViewId={selectedIcon}
                        serviceId={activeButton}
                        id={id}
                        setSearchString={setSearchString}
                        statusId={statusId}
                    />
                </div>
            )}
            {csButton && activeButton === 10 && (
                <div>
                    <ConformationStatement
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        id={id}
                        setSearchString={setSearchString}
                        serviceId={activeButton}
                        statusId={statusId}
                        taskViewId={selectedIcon}
                    />
                </div>
            )}
            {PayeButton && activeButton === 3 && (
                <div>
                    <PayeList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        setDropdownId={setDropdownId}
                        id={id}
                        setSearchString={setSearchString}
                        serviceId={activeButton}
                        statusId={statusId}
                        taskViewId={selectedIcon}
                    />
                </div>
            )}
            {PensionButton && activeButton === 4 && (
                <div>
                    <PensionList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        setDropdownId={setDropdownId}
                        id={id}
                        setSearchString={setSearchString}
                        serviceId={activeButton}
                        statusId={statusId}
                        taskViewId={selectedIcon}
                    />
                </div>
            )}
            {adHocButton && activeButton === 100 && (
                <div>
                    <AdhocList
                        thingsTodoList={thingsTodoList}
                        thingsTodoListCount={thingsTodoListCount}
                        setPagesize={setPagesize}
                        setPageNumber={setPageNumber}
                        id={id}
                        setSearchString={setSearchString}
                        serviceId={activeButton}
                        statusId={statusId}
                        taskViewId={selectedIcon}
                    />
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state: any): ThingstodoStateIntrface => {
    return {
        thingsTodoList: state.dashboardInfo.thingsTodoList,
        thingsTodoListCount: state.dashboardInfo.thingsTodoListCount,
    }
}

export default connect(mapStateToProps)(ThingsToDoTaps)
