import {
    Button,
    Col,
    Collapse,
    Form,
    Modal,
    Row,
    Select,
    Space,
    Table,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    type officerDocumentList,
    type CompanyAttachment,
    type CorporateDocumentView,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import {
    EyeOutlined,
    CloudDownloadOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import commontStyles from './document.less'
import commoncss from '../../../../../../Utils/Common.less'
import OfficerDocumentExpanView from './officerDocumentExpanView'
import OfficerDcumentForm from './officerDocumentForm'
import DocumentViewModelForm from './documentViewModel'
import { GetDocumenttypeList } from '../../../../../../Services/Common'
import ServiceDocumentUpload from './serviceDocumentUpload'
import {
    SaveOfficerDocument,
    getClientCompanyDetails,
} from '../../../../../../Services/Corporate'
import S3UploadMultiple from '../../../../../../Components/S3UploadMultiple'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const DocumentMain = (
    props: CorporateDocumentView & {
        corporateId: number
        companyservicedetails: any
    }
): React.ReactElement => {
    const {
        corporateTabs,
        documenttypeList,
        corporateId,
        companyservicedetails,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [viewModalVisible, setViewModalVisible] = useState(false)
    const [selectedDocumentType, setSelectedDocumentType] = useState<number>(1)
    const [companyAttachmentId, setCompanyAttachmentId] = useState<number>(0)
    const [selectedDocument, setSelectedDocument] = useState<string>('')
    const [downloadShowId, setDownloadShowId] = useState<number>(0)
    useEffect(() => {
        GetDocumenttypeList(dispatch, 3, 1)
    }, [corporateTabs])

    const OpenModel = (attachmentPath: string, dowId: number): void => {
        setSelectedDocument(attachmentPath)
        setViewModalVisible(true)
        setDownloadShowId(dowId)
    }

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    documentBaseIcon: string | undefined
                    attachmentPath: string
                }
            ) => (
                <Space size="middle">
                    <EyeOutlined
                        onClick={() => {
                            OpenModel(record.attachmentPath, 0)
                        }}
                    />
                    <a>
                        <CloudDownloadOutlined
                            onClick={() => {
                                OpenModel(record.attachmentPath, 1)
                            }}
                        />
                    </a>
                </Space>
            ),
        },
    ]

    const officerColums = [
        {
            title: 'Name',
            dataIndex: 'documentOwner',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    officerDocumentList: Array<{
                        documentBaseIcon: string | undefined
                        attachmentPath: string
                    }>
                }
            ) => (
                <Space size="middle">
                    {record.officerDocumentList?.map((doc, index) => (
                        <React.Fragment key={index}>
                            <EyeOutlined
                                onClick={() => {
                                    OpenModel(doc.attachmentPath, 0)
                                }}
                            />

                            <CloudDownloadOutlined
                                onClick={() => {
                                    OpenModel(doc.attachmentPath, 1)
                                }}
                            />
                        </React.Fragment>
                    ))}
                </Space>
            ),
        },
    ]

    const Servicecolumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    attachmentPath: string
                }
            ) => (
                <Space size="middle">
                    <>
                        <EyeOutlined
                            onClick={() => {
                                OpenModel(record?.attachmentPath, 0)
                            }}
                        />
                        <a>
                            <CloudDownloadOutlined
                                onClick={() => {
                                    OpenModel(record.attachmentPath, 1)
                                }}
                            />
                        </a>
                    </>
                </Space>
            ),
        },
    ]

    const filterServicecolumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    documentBaseIcon: string | undefined
                    attachmentPath: string
                }
            ) => <Space size="middle"></Space>,
        },
    ]

    const dataSource = corporateTabs?.officerDocumentHeaderList?.map(
        (item) => ({
            documentOwner: item.documentOwner,
            officerDocumentList: item.officerDocumentList,
        })
    )

    const expandableData = (record: {
        officerDocumentList: officerDocumentList[]
    }): JSX.Element => {
        return (
            <OfficerDocumentExpanView expandData={record.officerDocumentList} />
        )
    }

    const uniqueServiceDocuments = Array.from(
        new Set(
            corporateTabs?.serviceDocumentList?.map(
                (document) => document.companyAttachmentId
            )
        )
    )?.map((companyAttachmentId) =>
        corporateTabs?.serviceDocumentList?.find(
            (document) => document.companyAttachmentId === companyAttachmentId
        )
    )

    const handleDocumentTitleChange = (value: number): void => {
        setSelectedDocumentType(value)
        form.resetFields(['attachmentPath'])
    }

    useEffect(() => {
        const matchingDocument = corporateTabs?.generalDocumentList?.find(
            (document) => document.documentBaseId === selectedDocumentType
        )
        if (matchingDocument != null) {
            const companyAttachmentIds = matchingDocument.companyAttachmentId
            setCompanyAttachmentId(companyAttachmentIds)
        } else {
            setCompanyAttachmentId(0)
        }
    }, [selectedDocumentType])
    const activeServices = companyservicedetails?.data?.filter(
        (service: { status: string }) => service.status === 'Active'
    )

    const servicedocumentName = uniqueServiceDocuments?.map((service) =>
        service?.serviceName.toUpperCase()
    )

    // Filter out items from 'a' that have a service name present in 'activeServices'
    const filteredServiceData = activeServices?.filter(
        (item: { serviceName: string }) =>
            !servicedocumentName.includes(item.serviceName)
    )
    const pensionDocuments = uniqueServiceDocuments
        ?.filter((document) => document?.serviceName === 'Pension')
        .map((document) => ({
            ...document,
            key: document?.companyAttachmentId,
        }))

    const pensionServiceNames = pensionDocuments.map((doc) => doc.serviceName)

    // Filter out services from filteredServiceData
    const updatedServiceData = filteredServiceData?.filter(
        (service: { serviceName: string }) =>
            !pensionServiceNames.includes(service.serviceName)
    )

    const businessTypeId = 1
    const tabIndex = 3
    const fetchData = (): void => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            corporateId,
            tabIndex
        )
    }

    const handeleCompanDocumentSave = (): void => {
        let documentBaseNames = ''

        switch (selectedDocumentType) {
            case 1:
                documentBaseNames = 'Letter of Engagement'
                break
            case 2:
                documentBaseNames = 'Company Incorporation'
                break
            case 3:
                documentBaseNames = 'AML Document'
                break
            default:
                documentBaseNames = ''
                break
        }
        let attachmentPaths = ''
        if (uploadFileList?.length > 0) {
            attachmentPaths = uploadFileList[0]
        }
        const savedata = {
            companyAttachmentId: companyAttachmentId ?? 0,
            companyTypeId: 1,
            referenceId: corporateId,
            referenceId2: 0,
            documentBaseId: selectedDocumentType ?? 0,
            documentBaseName: documentBaseNames,
            attachmentPath: attachmentPaths ?? '',
            corporateId: 0,
            type: 'GENERAL',
            ppExpiryDate: '',
            dlExpiryDate: '',
            prExpiryDate: '',
        }
        void SaveOfficerDocument(dispatch, savedata)
        fetchData()
        form.resetFields(['attachmentPath'])
    }
    return (
        <>
            <Row>
                <Col span={24}>
                    <Collapse
                        expandIconPosition="end"
                        accordion
                        defaultActiveKey={['1']}
                    >
                        <Collapse.Panel
                            header="General Company Documents"
                            key="1"
                        >
                            <Row gutter={[24, 16]}>
                                <Col span={12}>
                                    <Table
                                        dataSource={
                                            corporateTabs?.generalDocumentList
                                        }
                                        columns={columns}
                                        pagination={false}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className={commoncss.formWrapper}>
                                        <Form
                                            name="complex-form"
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            layout={'vertical'}
                                            autoComplete="off"
                                            form={form}
                                        >
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <Form.Item<CompanyAttachment>
                                                        label="Document Type"
                                                        name="title"
                                                        className={
                                                            commontStyles.CorporteDocumentGeenreal
                                                        }
                                                    >
                                                        <Select
                                                            allowClear
                                                            placeholder="Select a Document Type"
                                                            onChange={(
                                                                value: number
                                                            ) => {
                                                                handleDocumentTitleChange(
                                                                    value
                                                                )
                                                            }}
                                                            options={
                                                                documenttypeList
                                                            }
                                                            defaultValue={1}
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) =>
                                                                option?.label
                                                                    ?.toLowerCase()
                                                                    .includes(
                                                                        input.toLowerCase()
                                                                    ) ?? false
                                                            }
                                                            showSearch
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <Form.Item<CompanyAttachment> name="attachmentPath">
                                                        <S3UploadMultiple
                                                            name={
                                                                'attachmentPath'
                                                            }
                                                            value={
                                                                uploadFileList
                                                            }
                                                            setFormData={
                                                                handleSetFormData
                                                            }
                                                            corporate={
                                                                'Corporate'
                                                            }
                                                            corporateid={
                                                                corporateId
                                                            }
                                                            documenttype={
                                                                'General'
                                                            }
                                                            documentbaseId={
                                                                selectedDocumentType
                                                            }
                                                            memberpersonId={0}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {ActivePremission(
                                                '3000',
                                                PermissionType.SAVE
                                            ) ? (
                                                <Row gutter={16}>
                                                    <Col offset={20} span={2}>
                                                        <Button
                                                            type="primary"
                                                            onClick={
                                                                handeleCompanDocumentSave
                                                            }
                                                        >
                                                            <SaveOutlined />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Collapse.Panel>

                        <Collapse.Panel header="Officer Documents" key="2">
                            <Row gutter={[24, 16]}>
                                <Col span={12}>
                                    <Table
                                        columns={officerColums}
                                        dataSource={dataSource}
                                        rowKey={(record) =>
                                            record.documentOwner
                                        }
                                        pagination={false}
                                        expandable={{
                                            expandedRowRender: expandableData,
                                            rowExpandable: () => true,
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <OfficerDcumentForm
                                        OfficerData={corporateTabs}
                                        Corporateid={corporateId}
                                        fetchData={fetchData}
                                    />
                                </Col>
                            </Row>
                        </Collapse.Panel>
                        {uniqueServiceDocuments
                            ?.filter(
                                (document) =>
                                    document?.serviceName !== 'Pension'
                            )
                            .map((document) => (
                                <Collapse.Panel
                                    header={
                                        <div>
                                            <span>{document?.title}</span>
                                        </div>
                                    }
                                    key={document?.companyAttachmentId ?? '1'}
                                >
                                    <Row gutter={[24, 16]}>
                                        <Col span={12}>
                                            <Table
                                                dataSource={
                                                    document != null
                                                        ? [document]
                                                        : []
                                                }
                                                columns={Servicecolumns}
                                                pagination={false}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <ServiceDocumentUpload
                                                corporateId={corporateId}
                                                companyAttachmentId={
                                                    document?.companyAttachmentId
                                                }
                                                serviceName={
                                                    document?.serviceName
                                                }
                                                documentBaseId={
                                                    document?.documentBaseId
                                                }
                                                documentType={document?.documentType.toString()}
                                                fetchData={fetchData}
                                            />
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            ))}
                        {pensionDocuments?.length > 0 && (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>Pension Document</span>
                                    </div>
                                }
                                key={'4'}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                pensionDocuments !== undefined
                                                    ? pensionDocuments.map(
                                                          (doc) => ({
                                                              ...doc,
                                                              attachmentPath:
                                                                  doc.attachmentPath ??
                                                                  '', // Add a default value or handle accordingly
                                                          })
                                                      )
                                                    : []
                                            }
                                            columns={Servicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            corporateId={corporateId}
                                            companyAttachmentId={0}
                                            serviceName={'Pension'}
                                            documentBaseId={0}
                                            documentType={''}
                                            pensionData={pensionDocuments}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        )}
                        {updatedServiceData?.map((document: any) => (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>
                                            {document?.serviceName} File
                                            Document
                                        </span>
                                    </div>
                                }
                                key={document?.serviceReferenceId}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                document != null
                                                    ? [document]
                                                    : []
                                            }
                                            columns={filterServicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            corporateId={corporateId}
                                            companyAttachmentId={0}
                                            serviceName={document?.serviceName}
                                            documentBaseId={
                                                document?.serviceName === 'VAT'
                                                    ? 8
                                                    : document?.serviceName ===
                                                        'PAYE'
                                                      ? 10
                                                      : document?.serviceName ===
                                                          'CT600'
                                                        ? 9
                                                        : document?.serviceName ===
                                                            'AA'
                                                          ? 16
                                                          : 0
                                            }
                                            documentType={'0'}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </Col>
            </Row>
            <Modal
                title="Documents"
                onCancel={() => {
                    setViewModalVisible(false)
                }}
                visible={viewModalVisible}
                footer={null}
                width={'50%'}
            >
                <DocumentViewModelForm
                    data={selectedDocument}
                    itshow={downloadShowId}
                />
            </Modal>
        </>
    )
}
const mapStateToProps = (state: any): CorporateDocumentView => {
    return {
        corporateTabs: state.client.corporateTaps,
        documenttypeList: state.common.documenttypeList,
    }
}

export default connect(mapStateToProps)(DocumentMain)
