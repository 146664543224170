import { connect, useDispatch } from 'react-redux'
import {
    type ManageUserDetailsListDataTypeInterface,
    type ManageUserDetailsFormDataInterface,
    type ManageUserDetailsListInterface,
} from '../../../Types/ManageUser'
import { type TableParamsInterface } from '../../../Types/CommonType'
import React, { useCallback, useEffect, useState } from 'react'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import commonStyles from '../../../Utils/Common.less'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import { GetParentCategoryList } from '../../../Services/GroupAndPartners'
import {
    ActiveInactiveUser,
    DeleteUser,
    GetUserInvitation,
    GetUsertList,
} from '../../../Services/ManageUser'
import calculatePagination from '../../../Utils/Pagination'
import {
    Space,
    type TableProps,
    Tooltip,
    Modal,
    Card,
    Switch,
    Table,
} from 'antd'
import { type ColumnsType } from 'antd/es/table'
import UserExpanview from './UserExpanView'
import UserInfoForm from './userInfoForm'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'
import { SendOutlined } from '@ant-design/icons'
export const UserInformationDefaultData = {
    userId: '0',
    firstName: '',
    middleName: '',
    surName: '',
    dateOfBirth: '',
    addressOne: '',
    addressTwo: '',
    town: '',
    country: '',
    postCode: '',
    userName: '',
    imageurl: '',
    emailAddress: '',
    contactNo: '',
    managerId: 0,
    managerName: '',
    workingStatus: '',
    userGroupId: 0,
    userGroupName: '',
    clientCategoryId: null,
    clientCategoryName: '',
    departmentId: null,
    departmentName: '',
    designationId: null,
    designationName: '',
    isActive: '',
    isDelete: '',
    isActivated: 0,
}

const UserInfomationList = (
    props: ManageUserDetailsListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { UserInfoList, UserInfoListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [userId, setUserId] = useState('')
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('Active')
    const [editModalTittle, setEditModalTittle] = useState('Create User')
    const [editData, setEditData] =
        useState<ManageUserDetailsFormDataInterface>(UserInformationDefaultData)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    useEffect(() => {
        GetParentCategoryList(dispatch)
    }, [])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetUsertList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: UserInfoListCount,
            },
        })
    }, [UserInfoListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        UserInfoListCount,
        itemsPerPage
    )

    const columns: ColumnsType<ManageUserDetailsListDataTypeInterface> = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            render: (_, record: ManageUserDetailsListDataTypeInterface) => (
                <span>
                    {record.firstName} {record.middleName} {record.surName}
                </span>
            ),
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Group & Partners',
            dataIndex: 'clientCategoryName',
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Contact No',
            dataIndex: 'contactNo',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: ManageUserDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('9000', PermissionType.UPDATE) && (
                            <Tooltip title="Edit User">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.userId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                record.isActive === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.isActive === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.userId,
                                        record.isActive
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('9000', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            (record?.userId).toString()
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        {record.isActivated === 0 && (
                            <Tooltip title="Resend Invite Email">
                                <SendOutlined
                                    onClick={() => {
                                        portalInvitation(record.userId)
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<ManageUserDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'firstName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let userId = ''
        if (!deleteModalStatus) {
            userId = id
        }
        setUserId(userId)
    }

    const deleteUser = (): void => {
        DeleteUser(dispatch, userId, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let userId = ''
        if (!activeInactiveModalStatus) {
            userId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setUserId(userId)
    }

    const openEditModal = (id: string): void => {
        const data = UserInfoList.find(
            (data: ManageUserDetailsFormDataInterface) => {
                return data.userId === id
            }
        )

        let tittle = 'Create User'
        if (id !== null && id !== undefined && id !== '') {
            tittle = 'Edit User'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? UserInformationDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const updateActiveInactiveDepartement = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        setUserId(userId)
        ActiveInactiveUser(dispatch, userId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const onSaveUser = (callBack: CallableFunction): void => {
        changeCreateEditModalStatus()
        getTableData()
        callBack()
    }

    const portalInvitation = (userId: string): void => {
        GetUserInvitation(dispatch, Number(userId))
    }
    const expandableData = (
        record: ManageUserDetailsListDataTypeInterface
    ): React.ReactElement => {
        return <UserExpanview userDetailsData={record} />
    }

    return (
        <>
            <Card
                title="User Information"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search by Name'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        rowKey="userId"
                        columns={columns}
                        dataSource={UserInfoList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this user. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteUser}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this user. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveDepartement}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <UserInfoForm onSave={onSaveUser} editData={editData} />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ManageUserDetailsListInterface => {
    return {
        UserInfoList: state.userrights.UserInfoList,
        UserInfoListCount: state.userrights.UserInfoListCount,
    }
}

export default connect(mapStateToProps)(UserInfomationList)
