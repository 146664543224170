import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Form, Input, Button } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import 'react-quill/dist/quill.snow.css'
import { SaveOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { GetSendEmailSetails, SaveEmail } from '../../../../Services/Invoice'
import S3UploadMultiple from '../../../../Components/S3UploadMultiple'
import ReactQuillComponet from '../../../../Components/ReactQillComponet'

const extractTextFromHtml = (htmlString: any): string => {
    const tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = htmlString
    return tempDivElement.innerHTML
}

const SendEmail = (): React.ReactElement => {
    const location = useLocation()
    const { tempId, invoiceIds } = location.state
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [emailText, setEmailText] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        if (tempId !== null && tempId !== undefined) {
            const GetEmailDeatils = async (): Promise<void> => {
                const emaildata = await GetSendEmailSetails(
                    dispatch,
                    invoiceIds,
                    tempId
                )

                form.setFieldsValue(emaildata)
                const description = extractTextFromHtml(emaildata?.description)
                const task = convertHtmlToText(description)
                setEmailText(task)
            }
            void GetEmailDeatils()
        }
    }, [tempId])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const onSave = (): void => {
        void onSaveEmail()
    }
    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()
            // const dataTosave = {
            //     clientEmailId: 0,
            //     templateId: tempId,
            //     serviceProcessId: 0,
            //     companyTypeId: 6,
            //     referenceId: 0,
            //     serviceActionId: 0,
            //     serviceActionStepId: 0,
            //     fromAddress: 'info@iykons.com',
            //     toAddress: formData.emailAddress,
            //     ccAddress: formData.emailCC,
            //     bccAddress: formData.emailBcc,
            //     subject: formData.subject,
            //     description: emailText,
            //     emailStatus: 'Sucsss',
            //     serviceId: 0,
            //     contactName: '',
            //     invoiceId: invoiceIds,
            // }
            const dataTosave = {
                clientEmailId: 0,
                templateId: tempId,
                invoiceId: invoiceIds,
                companyTypeId: 6,
                referenceId: 0,
                serviceActionId: 0,
                serviceActionStepId: 0,
                fromAddress: 'info@iykons.com',
                toAddress: formData.toAddress,
                ccAddress: formData.ccAddress,
                bccAddress: formData.bccAddress,
                subject: formData.subject,
                description: emailText,
                emailStatus: 'Pending',
                serviceId: 0,
                fromName: formData.contactName,
                clientEmailTypeId: 16,
                uniqueCode: '',
            }
            const htmlMessage = 'Invoice'
            void SaveEmail(dispatch, dataTosave, uploadFileList, htmlMessage)

            form.resetFields()
            navigate(`/invoicing/invoic-status`)
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const convertHtmlToText = (html: string): string => {
        const doc = new DOMParser().parseFromString(html, 'text/html')
        return doc.body.textContent ?? ''
    }

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }

    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }
    return (
        <Form
            name="complex-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            requiredMark={customizeRequiredMark}
        >
            <Card className={commonStyles.card}>
                <Form.Item name="contactName" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Email To"
                            name="toAddress"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Email CC" name="ccAddress">
                            <Input />
                        </Form.Item>
                        <span>
                            <i>
                                Note:-This format only accepted
                                (example@gmail.com,example1@gmail.com,example2@gmail.com)
                            </i>
                        </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Email BCC "
                            name="bccAddress"
                            rules={
                                [
                                    // {
                                    //     type: 'email',
                                    //     message: 'Please input Valid email!',
                                    // },
                                ]
                            }
                        >
                            <Input />
                        </Form.Item>
                        <span>
                            <i>
                                Note:-This format only accepted
                                (example@gmail.com,example1@gmail.com,example2@gmail.com)
                            </i>
                        </span>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Subject',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Description">
                            <ReactQuillComponet
                                value={emailText}
                                onChange={(value: string) => {
                                    onDescriptinChange(value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginBottom: 20 }}>
                    <Col span={24}>
                        <Form.Item label="Attachment" name="imageUpload">
                            <S3UploadMultiple
                                name={'imageUpload'}
                                value={uploadFileList}
                                setFormData={handleSetFormData}
                                corporate={''}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: 20 }}>
                    <Col span={24}></Col>
                </Row>
                <Row>
                    <Col offset={22} span={2}>
                        <Form.Item>
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                            >
                                <SaveOutlined />
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
    )
}

export default SendEmail
