import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Col, Row, Form, Input, Table, Button, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import 'react-quill/dist/quill.snow.css'
import { SaveOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import {
    type savebulkemial,
    type BulkmailFormStateInterface,
    type BulkmailListDataTypeInterface,
    type EmailAttachmentType,
} from '../../../Types/BulkEmail'
import { commonTableParams } from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import { type TableParamsInterface } from '../../../Types/CommonType'
import { SaveBulkEmail } from '../../../Services/BulkEmail'
import dayjs from 'dayjs'
import S3UploadMultiple from '../../../Components/S3UploadMultiple'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'
import ReactQuillComponet from '../../../Components/ReactQillComponet'
import S3UploadExcel from '../../../Components/S3UploadExcel'

const SearchForm = (
    props: BulkmailFormStateInterface & {
        clientCategoryId: number
        statusId: number
        moduleIds: number[]
    }
): React.ReactElement => {
    const {
        contactdepartmentDropDownList,
        contactdepartmentDropDownListCount,
        clientCategoryId,
        statusId,
        moduleIds,
    } = props

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedEmailAddresses, setSelectedEmailAddresses] = useState<
        string[]
    >([])
    const bulk = contactdepartmentDropDownList?.length

    const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
        setSelectedRowKeys(newSelectedRowKeys)

        const selectedEmails = contactdepartmentDropDownList
            ?.filter((item) => newSelectedRowKeys.includes(item.key))
            ?.map((item) => item.emailAddress)

        setSelectedEmailAddresses(selectedEmails)
    }

    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [emailText, setEmailText] = useState('')
    const [change64, setChange64] = useState<string | undefined>(undefined)
    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: contactdepartmentDropDownListCount,
            },
        })
    }, [contactdepartmentDropDownListCount])
    const setFormData = (name: string, location: string): void => {
        form.setFieldValue(name, location)
    }
    const setBase64 = (name: string | undefined): void => {
        setChange64(name)
    }

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(currentPage, bulk, itemsPerPage)
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const columns: ColumnsType<BulkmailListDataTypeInterface> = [
        {
            title: 'Name',
            dataIndex: 'contactName',
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
    ]

    const onChangeTableParams: TableProps<BulkmailListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value?.length === 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onSave = (): void => {
        void onSavePhone()
    }

    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const uniqueCode = dayjs().valueOf().toString()

            const folderName: string =
                'Document/BulkMailAttachment/' + uniqueCode + '/'

            const emailAttachmentItem: EmailAttachmentType = {
                fileContent: change64 ?? '',
                fileName: folderName + formData?.imageUpload?.split('/').pop(),
            }

            const emailAttachmentItemList: EmailAttachmentType[] = [
                emailAttachmentItem,
            ]

            const dataToSave = {
                clientCategoryId,
                statusId,
                moduleIds: moduleIds.join(','),
                additionalEmail: formData.additionalEmail ?? '',
                subject: formData.subject,
                description: emailText,
                emailAttachment:
                    formData?.imageUpload !== null &&
                    formData?.imageUpload !== undefined
                        ? emailAttachmentItemList
                        : null,
                fromMail: 'info@iykons.com',
                fromName: 'Iykons Private Limited',
                toMail: '',
                toClientMailIds: selectedEmailAddresses,
                uniqueCode,
            }

            await SaveBulkEmail(dispatch, dataToSave as savebulkemial)

            form.resetFields()
        } catch (error) {}
    }
    const handleDownload = (): void => {
        const uploadedFileUrl = form.getFieldValue('imageUpload')

        if (uploadedFileUrl !== null) {
            window.open(uploadedFileUrl, '_blank')
        } else {
            console.log('No file uploaded to download.')
        }
    }

    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }
    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }

    return (
        <Form
            name="complex-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            requiredMark={customizeRequiredMark}
            initialValues={{
                uploadedFiles: [],
            }}
        >
            <Card className={commonStyles.card}>
                <Row gutter={16} align="middle">
                    <div className={commonStyles.corpList}>
                        <Col span={24}>
                            <SearchInput
                                placeHolder={'Search'}
                                onSearch={onChangeFilter}
                                onChange={onChangeText}
                                width="478"
                            />
                        </Col>
                    </div>
                </Row>
                <div className={commonStyles.table}>
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={contactdepartmentDropDownList?.filter(
                            (item) => {
                                const fullName =
                                    `${item.contactName} ${item.contactName}`.toLowerCase()
                                return fullName.includes(
                                    tableParams?.filter.fieldValue1.toLowerCase()
                                )
                            }
                        )}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <Col span={24}>
                    <Form.Item label="Additional Email" name="additionalEmail">
                        <Input />
                        <span>
                            <i>
                                Note:-This format only accepted
                                (example@gmail.com,example1@gmail.com,example2@gmail.com)
                            </i>
                        </span>
                    </Form.Item>
                </Col>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name="imageUpload">
                            <S3UploadExcel
                                name={'imageUpload'}
                                value={form.getFieldValue('imageUpload')}
                                setFormData={setFormData}
                                setBase64={setBase64}
                                corporate={''}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                            />
                        </Form.Item>
                    </Col>

                    <Form.Item>
                        <Tooltip title="Upload Excel">
                            <Button type="primary">
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Form.Item>

                    <Col>
                        <Form.Item>
                            <Tooltip title="Download Format">
                                <Button type="link" onClick={handleDownload}>
                                    <CloudDownloadOutlined />
                                </Button>
                            </Tooltip>
                        </Form.Item>
                    </Col>
                </Row>

                <Col span={24}>
                    <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Subject!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Description" name="description">
                            <ReactQuillComponet
                                value={emailText}
                                onChange={(value: string) => {
                                    onDescriptinChange(value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item label="Attachment" name="imageUpload1">
                            <S3UploadMultiple
                                name={'imageUpload1'}
                                value={uploadFileList}
                                setFormData={handleSetFormData}
                                corporate={''}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {ActivePremission('12000', PermissionType.SAVE) ? (
                    <Row>
                        <Col offset={22} span={2}>
                            <Form.Item>
                                <Tooltip title="Save Mail">
                                    <Button
                                        type="primary"
                                        onClick={onSave}
                                        size="middle"
                                    >
                                        <SaveOutlined />
                                    </Button>
                                </Tooltip>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </Card>
        </Form>
    )
}

const mapStateToProps = (state: any): BulkmailFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        contactdepartmentDropDownList:
            state.initial.contactdepartmentDropDownList,
        contactdepartmentDropDownListCount:
            state.initial.contactdepartmentDropDownListCount,
    }
}
export default connect(mapStateToProps)(SearchForm)
