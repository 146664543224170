import type { Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import {
    setAuthState,
    setLoginState,
    pageReloadAuth,
    pageLoginPinAuth,
} from '../Reducer/AuthReducer'
import type {
    StoreUser,
    loginDataInterface,
    pinDataInterface,
} from '../Types/Auth'
import { ErrorMessage } from '../Utils/Notification'
import { flag } from '../Reducer/DashboardFlagReducer'
import { setLoadingStatus } from '../Reducer/Common'

export const LoginApi = (
    dispatch: Dispatch,
    data: loginDataInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .post('/api/Authenticate/AuthenticateUser', data) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.status === 200) {
                dispatch(setLoginState({ status: true, ...data }))
                localStorage.setItem(
                    'loginSessionCode',
                    response?.data?.loginSessionCode
                )
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch(() => {
            ErrorMessage('Invalid Username or Password')
        })
}

export const PinApi = (dispatch: Dispatch, data: pinDataInterface): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .post('/api/Authenticate/AuthenticateViaPIN', data) // You can omit the base URL since it's set in the axiosConfig
        .then((response) => {
            if (response.data.statusCode === 200) {
                const accessToken = response?.data?.data?.accessToken
                if (accessToken !== null) {
                    localStorage.setItem('jwtToken', accessToken)
                    const storeUser: StoreUser = {
                        userId: response?.data?.data?.userId,
                        userName: response?.data?.data?.userName,
                        firstName: response?.data?.data?.firstName,
                        middleName: response?.data?.data?.middleName,
                        surName: response?.data?.data?.surName,
                        imageUrl: response?.data?.data?.imageUrl,
                        emailAddress: response?.data?.data?.emailAddress,
                        contactNo: response?.data?.data?.contactNo,
                        accessToken: response?.data?.data?.accessToken,
                        userGroupId: response?.data?.data?.userGroupId,
                        clientCategoryId:
                            response?.data?.data?.clientCategoryId,
                        isParentCompany: response?.data?.data?.isParentCompany,
                        departmentId: response?.data?.data?.departmentId,
                        designationId: response?.data?.data?.designationId,
                        userRights: response?.data?.data?.userRights,
                        departments: response?.data?.data?.departments,
                        departmentReports:
                            response?.data?.data?.departmentReports,
                        departmentMenus: response?.data?.data?.departmentMenus,
                    }
                    localStorage.setItem('user', JSON.stringify(storeUser))
                    localStorage.removeItem('loginSessionCode')
                    dispatch(flag(1))
                    location.reload()
                } else {
                    ErrorMessage('Access token is missing in the response.')
                }
            } else {
                ErrorMessage(response.data.error.message)
                dispatch(setAuthState({ status: false }))
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch(() => {
            ErrorMessage('Invalid Access Token')
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const authenticateWithJwt = (dispatch: Dispatch): void => {
    // TODO need to check the jwt token avaibale from localstorage if so return to home page otherwise login
    dispatch(pageReloadAuth())
}

export const chetPinVidation = (dispatch: Dispatch): void => {
    dispatch(pageLoginPinAuth())
}
