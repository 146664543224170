import {
    Col,
    Row,
    Form,
    Switch,
    InputNumber,
    Dropdown,
    Button,
    Input,
    type MenuProps,
    message,
    Tooltip,
    Modal,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
    SaveOutlined,
    EuroOutlined,
    ClockCircleOutlined,
    DownOutlined,
} from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import complianceStyles from './compliance.less'
import React, { useEffect, useState } from 'react'
import type {
    ClientCompanyActiveService,
    ClientCompanyControlData,
    ClientCompanyVerification,
    ClientControlData,
    ClientVerificationData,
    Complainceprop,
    ComplaincepropView,
    GenerateEmails,
    ServiceData,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import FullWidthModal from '../../../../../../Components/FullWidthModal'
import {
    EnableServiceForComplince,
    GenerateEmail,
    getClientCompanyDetails,
    GetQuotationPreview,
    SaveClientCompanyActiveService,
    SaveClientCompanyVerification,
    SaveCompanyControl,
    // getClientCompanyDetails,
} from '../../../../../../Services/Parnership'
import DocumentVerification from './documentlist'
import ControlItems from './controllItem'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import {
    ErrorMessage,
    SuccessMessage,
} from '../../../../../../../src/Utils/Notification'
import { GetViaSignable } from '../../../../../../Services/Common'
import {
    SinableDocumentAML,
    SinableDocumentLOE,
    SinableDocumentQutation,
} from '../../../Corporate/Buttons/Compliance/DownloadSignableDocument'
import SignableHistory from '../../../Corporate/Buttons/Compliance/signableHistory'
import { setLoadingStatus } from '../../../../../../Reducer/Common'

const { TextArea } = Input
const Compliance = (props: Complainceprop): React.ReactElement => {
    const { partnershipTaps, partnershipId, onbordingId } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [switchStates, setSwitchStates] = useState<Record<number, boolean>>(
        {}
    )
    const [, setClientCompanyServiceViewLists] = useState<ServiceData[]>([])
    const [, setClientCompanyVerificationViewList] = useState<
        ClientCompanyVerification[]
    >([])
    const [, setClientCompanyControlViewList] = useState<ClientControlData[]>(
        []
    )
    const [docuswitchStates, setdocuSwitchStates] = useState<
        Record<number, boolean>
    >({})

    const clientCompanyServiceViewList =
        partnershipTaps?.clientCompanyServiceViewList ?? []
    const clientCompanyVerificationViewList =
        partnershipTaps?.clientCompanyVerificationViewList ?? []
    const clientControlViewList = partnershipTaps?.clientControlViewList ?? []
    const [showDatePickerMap, setcontrolSwitchStates] = useState<
        Record<number, boolean>
    >({})
    const [servicemodelModalStatus, setServicemodel] = useState(false)
    const [CoplinceEmailmodelStatus, setCoplinceEmailmodel] = useState(false)
    const [ComplinceSignmodelStatus, setComplinceSignmodel] = useState(false)
    const [QuptionEmailmodelStatus, setQuptionEmailmodel] = useState(false)
    const [isAnnualEnabled, setIsAnnualEnabled] = useState(false)
    const [isMonthlyEnabled, setIsMonthlyEnabled] = useState(true)
    const [isIncludeVATEnabled, setIsIncludeVATEnabled] = useState(false)
    const [vatAmount, setVATAmount] = useState(0)
    const [total, setTotal] = useState(0)
    const [form] = Form.useForm()
    const [totalAmount, setTotalAmount] = useState(0)
    const [dateValues, setDateValues] = useState<
        Record<number, dayjs.Dayjs | null>
    >({})
    const [annualTotal, setAnnualTotal] = useState(0)
    const [monthlyTotal, setMonthlyTotal] = useState(0)
    const [viewModalVisible, setViewModalVisible] = useState(false)
    const [contactInfo, setCompanycontactInfo] = useState<any>({})

    useEffect(() => {
        const fetchDataContactInfo = async (): Promise<void> => {
            try {
                const data = await getClientCompanyDetails(
                    dispatch,
                    1,
                    partnershipId,
                    5
                )
                setCompanycontactInfo(data)
            } catch (error) {}
        }
        void fetchDataContactInfo()
    }, [])
    const handleAnnualSwitchChange = (checked: boolean): void => {
        setIsAnnualEnabled(checked)
        if (checked) {
            setIsMonthlyEnabled(false)
        }
    }

    const handleMonthlySwitchChange = (checked: boolean): void => {
        setIsMonthlyEnabled(checked)
        if (checked) {
            setIsAnnualEnabled(false)
        }
    }

    const closeServiceModalFunction = (): void => {
        setServicemodel(false)
    }

    const openDeleteModal = (): void => {
        setServicemodel(true)
    }

    const closeCoplinceEmail = (): void => {
        setCoplinceEmailmodel(false)
    }

    const openCompilinceEmailModel = (): void => {
        setCoplinceEmailmodel(true)
    }

    const closeCoplinceSign = (): void => {
        setComplinceSignmodel(false)
    }

    const openCompilinceSignModel = (): void => {
        setComplinceSignmodel(true)
    }

    const createSignableDoc = (): void => {
        void createSignableDocments()
    }

    const createSignableDocments = async (): Promise<void> => {
        const previewDatas = await GetQuotationPreview(dispatch, partnershipId)

        const previewqutation = previewDatas?.data
        try {
            const AMLurl = await SinableDocumentAML(previewqutation)
            const dataToSave = {
                businessTypeId: 4,
                referenceId: previewqutation.quotationHeader?.referenceId,
                appConnectId: 1,
                pdfUrl: AMLurl,
                title: 'Anti-Money Laundering Document',
                fileName: `${previewqutation.quotationHeader?.quotationId}_AML.pdf`,
                serviceProcessId: 0,
            }

            void GetViaSignable(dispatch, dataToSave)
        } catch (error) {
            console.error('Error:', error)
        }

        try {
            const LOEurl = await SinableDocumentLOE(previewqutation)
            const dataToSave = {
                businessTypeId: 4,
                referenceId: previewqutation.quotationHeader?.referenceId,
                appConnectId: 2,
                pdfUrl: LOEurl,
                title: 'Letter of Engagement Document',
                fileName: `${previewqutation.quotationHeader?.quotationId}_LOE.pdf`,
                serviceProcessId: 0,
            }

            void GetViaSignable(dispatch, dataToSave)
        } catch (error) {
            console.error('Error:', error)
        }

        // try {
        //     const QUDUrl = await SinableDocumentQutation(previewqutation)
        //     const dataToSave = {
        //         businessTypeId: 4,
        //         referenceId: previewqutation.quotationHeader?.referenceId,
        //         appConnectId: 3,
        //         pdfUrl: QUDUrl,
        //         title: 'Qutation Document',
        //         fileName: `${previewqutation.quotationHeader?.quotationId}_QUO.pdf`,
        //         serviceProcessId: 0,
        //     }

        //     void GetViaSignable(dispatch, dataToSave)
        // } catch (error) {
        //     console.error('Error:', error)
        // }
        dispatch(setLoadingStatus({ status: true }))
        setTimeout(() => {
            setComplinceSignmodel(false)
            dispatch(setLoadingStatus({ status: false }))
        }, 3000)
    }

    const closeQuptionEmail = (): void => {
        setQuptionEmailmodel(false)
    }

    const openQuptionEmailModel = (): void => {
        setQuptionEmailmodel(true)
    }

    const GetQuotationPreviews = async (): Promise<void> => {
        const previewDatas = await GetQuotationPreview(dispatch, partnershipId)

        const previewqutation = previewDatas?.data
        if (previewqutation != null) {
            navigate(
                `/clients/registered-clients/partnership-view/quotation-preview`,
                {
                    state: { previewqutation },
                }
            )
        }
    }

    const viewLetterOfEngagement = async (): Promise<void> => {
        const previewDatas = await GetQuotationPreview(dispatch, partnershipId)

        const previewqutation = previewDatas?.data
        if (previewqutation != null) {
            navigate(
                `/clients/registered-clients/partnership-view/letterof-engagement`,
                {
                    state: { previewqutation },
                }
            )
        }
    }
    const antimoneyLaundering = async (): Promise<void> => {
        const previewDatas = await GetQuotationPreview(dispatch, partnershipId)

        const previewqutation = previewDatas?.data
        if (previewqutation != null) {
            navigate(
                `/clients/registered-clients/partnership-view/anti-money-laundering`,
                {
                    state: { previewqutation },
                }
            )
        }
    }

    const handleQuotationPreview = (): void => {
        void GetQuotationPreviews()
    }

    const handleLetterOfEngagementPreview = (): void => {
        void viewLetterOfEngagement()
    }

    const handleQAntiMoneyPreview = (): void => {
        void antimoneyLaundering()
    }

    const itemcompliance: MenuProps['items'] = [
        {
            key: '1',
            label: 'Download & View Letter Engagement',
            onClick: handleLetterOfEngagementPreview,
        },
        {
            key: '2',
            label: 'Download & View Anti-Money Laundering',
            onClick: handleQAntiMoneyPreview,
        },
        {
            key: '3',
            label: 'Send Email',
            onClick: openCompilinceEmailModel,
        },
        {
            key: '4',
            label: (
                <span className={complianceStyles.right_alignButtonIcon}>
                    <div
                        onClick={() => {
                            openCompilinceSignModel()
                        }}
                    >
                        Get Sign via Signable
                    </div>
                    <Tooltip title={'Signable History'}>
                        <div
                            onClick={() => {
                                setViewModalVisible(true)
                                setComplinceSignmodel(false)
                            }}
                        >
                            <ClockCircleOutlined />
                        </div>
                    </Tooltip>
                </span>
            ),
        },
    ]

    const itemsQuotation: MenuProps['items'] = [
        {
            key: '5',
            label: 'Download & View Quotation',
            onClick: handleQuotationPreview,
        },
        {
            key: '6',
            label: 'Send Email',
            onClick: openQuptionEmailModel,
        },
    ]

    useEffect(() => {
        const initialSwitchStates: Record<number, boolean> = {}
        const initialDocSwitchStates: Record<number, boolean> = {}
        const initialControlSwitchStates: Record<number, boolean> = {}
        clientCompanyServiceViewList.forEach((service: ServiceData) => {
            initialSwitchStates[service.serviceId] = service.status === 'Active'
        })

        clientCompanyVerificationViewList.forEach(
            (Verification: ClientVerificationData) => {
                initialDocSwitchStates[
                    Verification.clientCompanyVerificationId
                ] = Verification.status === 'Verified'
            }
        )

        clientControlViewList.forEach((control: ClientControlData) => {
            initialControlSwitchStates[control.clientCompanyControlId] =
                control.value === 'Active'
        })
        setcontrolSwitchStates(initialControlSwitchStates)
        setdocuSwitchStates(initialDocSwitchStates)
        setSwitchStates(initialSwitchStates)
    }, [
        clientCompanyServiceViewList,
        clientCompanyVerificationViewList,
        clientControlViewList,
    ])

    const handleSwitchChange = (serviceId: number, checked: boolean): void => {
        setSwitchStates((prevState) => ({
            ...prevState,
            [serviceId]: checked,
        }))
        if (!checked) {
            form.setFieldsValue({
                [`serviceAmount-${serviceId}`]: 0,
            })
        }
        calculateTotal()
        form.setFieldsValue({
            [`status-${serviceId}`]: checked ? 'Active' : 'Inactive',
        })
        const updatedServiceList = clientCompanyServiceViewList?.map(
            (service: ServiceData) => {
                if (service.serviceId === serviceId) {
                    return {
                        ...service,
                        status: checked ? 'Active' : 'Inactive',
                    }
                }
                return service
            }
        )
        setClientCompanyServiceViewLists(updatedServiceList)
    }

    const handleSwitchChangeControll = (
        clientControlId: number,
        checked: boolean
    ): void => {
        setcontrolSwitchStates((prevState) => ({
            ...prevState,
            [clientControlId]: checked,
        }))
        form.setFieldsValue({
            [`Controllstatus-${clientControlId}`]: checked
                ? 'Active'
                : 'Inactive',
        })
        const updatedControllList = clientControlViewList?.map(
            (Controll: ClientControlData) => {
                if (Controll.clientControlId === clientControlId) {
                    return {
                        ...Controll,
                        value: checked ? 'Active' : 'Inactive',
                    }
                }
                return Controll
            }
        )
        setClientCompanyControlViewList(updatedControllList)

        if (!checked) {
            handleDateChange(null, clientControlId)
        }
    }

    useEffect(() => {
        setTotal(monthlyTotal)
        form.setFieldValue('serviceAmountTotal', monthlyTotal)
        updateVATAndTotal(monthlyTotal, isIncludeVATEnabled)
    }, [monthlyTotal])

    useEffect(() => {
        setTotal(annualTotal)
        form.setFieldValue('serviceAmountTotal', annualTotal)
        updateVATAndTotal(annualTotal, isIncludeVATEnabled)
    }, [annualTotal])

    const handleSwitchChangeDocument = (
        verificationId: number,
        checked: boolean
    ): void => {
        setdocuSwitchStates((prevState) => ({
            ...prevState,
            [verificationId]: checked,
        }))

        form.setFieldsValue({
            [`corporateDocstatus-${verificationId}`]: checked
                ? 'Verified'
                : 'Pending',
        })
        const updatedList = clientCompanyVerificationViewList?.map(
            (verificationItem: any) => {
                if (verificationItem.clientVerificationId === verificationId) {
                    return {
                        ...verificationItem,
                        status: checked ? 'Verified' : 'Pending',
                    }
                }
                return verificationItem
            }
        )
        setClientCompanyVerificationViewList(updatedList)
    }

    const calculateTotal = (): void => {
        let totalAmount = 0

        clientCompanyServiceViewList.forEach((service: ServiceData) => {
            const fieldValue = form.getFieldValue(
                `serviceAmount-${service.serviceId}`
            )
            if (!isNaN(fieldValue)) {
                totalAmount += fieldValue
            }
        })

        setTotal(totalAmount)
        form.setFieldValue('subscriptionTypeTotal', totalAmount)
        form.setFieldValue('serviceAmountTotal', totalAmount)
        updateVATAndTotal(totalAmount, isIncludeVATEnabled)
    }

    const handleIncludeVATSwitchChange = (checked: boolean): void => {
        setIsIncludeVATEnabled(checked)
        updateVATAndTotal(total, checked)
    }

    const calculateVATAmount = (totalAmount: number): number => {
        return totalAmount * 0.2
    }

    const updateVATAndTotal = (
        totalAmount: number,
        includeVAT: boolean
    ): void => {
        let newTotalAmount = totalAmount
        const calculatedVATAmount = calculateVATAmount(totalAmount)
        setVATAmount(calculatedVATAmount)
        if (includeVAT) {
            newTotalAmount = newTotalAmount + calculatedVATAmount
            setTotalAmount(newTotalAmount)
        } else {
            setTotalAmount(totalAmount)
            setVATAmount(0)
        }
        form.setFields([
            {
                name: 'vatAmount',
                value: isIncludeVATEnabled ? calculatedVATAmount : 0,
            },
        ])

        form.setFieldValue('serviceAmountTotal', newTotalAmount)
    }

    useEffect(() => {
        updateVATAndTotal(total, isIncludeVATEnabled)
    }, [isIncludeVATEnabled])

    const handleSaveButtonClick = (): void => {
        try {
            for (const item of clientCompanyServiceViewList) {
                const mappedData = mapCompanyActiveserviceData(item)
                void SaveClientCompanyActiveService(dispatch, mappedData)
            }

            for (const item of clientCompanyVerificationViewList) {
                const mappedData = mapCompanyVerificationData(item)
                void SaveClientCompanyVerification(dispatch, mappedData)
            }

            for (const item of clientControlViewList) {
                const mappedData = mapCompanyControlData(item)
                void SaveCompanyControl(dispatch, mappedData)
            }
            SuccessMessage('Successfully saved data')
        } catch (error) {
            void message.error('An error occurred while saving data')
            ErrorMessage('An error occurred while saving data')
        }
    }

    const mapCompanyActiveserviceData = (
        item: ServiceData
    ): ClientCompanyActiveService => {
        const isActive = switchStates[item.serviceId] || false
        const servicestatus = isActive ? 'Active' : 'Inactive'
        const textAreaValue =
            form.getFieldValue(`otherServiceDescription`) ?? ''
        const otherServiceDescriptions = isActive ? textAreaValue : ''
        const ServiceAmountss =
            form.getFieldValue(`serviceAmount-${item.serviceId}`) ?? 0
        const serviceAmounts = isActive ? ServiceAmountss : 0
        const serviceAmountTotals = total
        const vatAmounts = vatAmount
        const IncludeVats = isIncludeVATEnabled ? totalAmount : 0
        const mappedData: ClientCompanyActiveService = {
            clientCompanyActiveServiceId: item.serviceReferenceId,
            companyType: 4,
            referenceId: partnershipId,
            serviceId: item.serviceId,
            status: servicestatus,
            serviceAmount: serviceAmounts,
            serviceAmountTotal: serviceAmountTotals,
            subscriptionType: isAnnualEnabled ? 0 : 1,
            displayType: item.displayType,
            otherServiceDescription: otherServiceDescriptions,
            includeVat: IncludeVats,
            vatAmount: vatAmounts,
        }

        return mappedData
    }

    const mapCompanyVerificationData = (
        item: ClientVerificationData
    ): ClientCompanyVerification => {
        const isActive = docuswitchStates[item.clientVerificationId] || false
        const documentStatus = isActive ? 'Verified' : 'Pending'
        const mappedData: ClientCompanyVerification = {
            clientCompanyVerificationId: item.clientCompanyVerificationId,
            clientVerificationId: item.clientVerificationId,
            companyType: 4,
            referenceId: partnershipId,
            status: documentStatus,
        }

        return mappedData
    }

    const mapCompanyControlData = (
        item: ClientControlData
    ): ClientCompanyControlData => {
        const isActive = showDatePickerMap[item.clientControlId] || false
        const documentStatus = isActive ? 'Active' : 'Inactive'
        const clientCompanyControlId =
            dateValues[item.clientControlId]?.format('DD/MM/YYYY') ?? ''
        const mappedData: ClientCompanyControlData = {
            clientCompanyControlId: item.clientCompanyControlId,
            clientControlId: item.clientControlId,
            companyType: 4,
            referenceId: partnershipId,
            value:
                item.keyDataType === 'Bool'
                    ? documentStatus
                    : clientCompanyControlId !== 'Invalid Date'
                      ? clientCompanyControlId
                      : '',
        }
        return mappedData
    }
    const dateFields = clientControlViewList?.filter(
        (controlItem: ClientControlData) =>
            controlItem.keyDataType === 'DateTime'
    )
    useEffect(() => {
        const initialDateValues: Record<number, dayjs.Dayjs | null> = {}

        dateFields.forEach((controlItem: ClientControlData) => {
            if (controlItem.keyDataType === 'DateTime') {
                const possibleFormats = ['DD/MM/YYYY', 'YYYY/MM/DD']
                const parsedDate = dayjs(
                    controlItem.value,
                    possibleFormats,
                    true
                )

                initialDateValues[controlItem.clientControlId] =
                    parsedDate.isValid() ? parsedDate : null
            }
        })

        setDateValues(initialDateValues)
    }, [clientControlViewList])

    const handleDateChange = (
        date: dayjs.Dayjs | null,
        controlId: number
    ): void => {
        setDateValues((prevDateValues) => ({
            ...prevDateValues,
            [controlId]: date,
        }))
        form.setFieldsValue({
            [`values-${controlId}`]: date,
        })
    }

    const handelEnableServiceClick = async (
        refernceId: number
    ): Promise<void> => {
        await EnableServiceForComplince(dispatch, refernceId)
        closeServiceModalFunction()
    }
    const GenerateEmailss = (): void => {
        void GenerateEmailsservice()
    }
    const GenerateEmailsservice = async (): Promise<void> => {
        const sendData: GenerateEmails = {
            clientEmailId: 0,
            templateId: 0,
            serviceProcessId: -7,
            companyTypeId: 1,
            referenceId: partnershipId,
            serviceActionId: 0,
            serviceActionStepId: 0,
            fromAddress: 'info@iykons.com',
            toAddress: contactInfo?.data?.emailaddress,
            ccAddress: '',
            bccAddress: 'info@iykons.com',
            subject:
                'Iykons Limited would like authorisation to take payment from you',
            description: '',
            IykonsBCCAddress: 'info@iykons.com',
        }
        try {
            const previewDatas = await GetQuotationPreview(
                dispatch,
                partnershipId
            )

            const previewqutation = previewDatas?.data
            const QUDUrl = await SinableDocumentQutation(previewqutation)
            const dataToSave = {
                businessTypeId: 1,
                referenceId: previewqutation.quotationHeader?.referenceId,
                appConnectId: 3,
                pdfUrl: QUDUrl,
                title: 'Qutation Document',
                fileName: `${previewqutation.quotationHeader?.quotationId}_QUO.pdf`,
                serviceProcessId: 0,
            }

            void GetViaSignable(dispatch, dataToSave)
        } catch (error) {
            console.error('Error:', error)
        }

        void GenerateEmail(dispatch, sendData)
        closeCoplinceEmail()
        closeQuptionEmail()
    }
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true, serviceAmountTotal: total }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
            >
                <>
                    <span className={complianceStyles.serviceItem}>
                        <b>Services Provider</b>
                    </span>
                    <Row gutter={[8, 24]}>
                        <Col span={12}>
                            {Array.isArray(clientCompanyServiceViewList) &&
                                clientCompanyServiceViewList?.map(
                                    (service: ServiceData, index) => (
                                        <Row
                                            gutter={[8, 32]}
                                            key={service.serviceId}
                                            className={
                                                complianceStyles.serviceItem
                                            }
                                        >
                                            <Col span={14}>
                                                <div>
                                                    <Form.Item
                                                        name={`status-${service.serviceId}`}
                                                    >
                                                        <Switch
                                                            checked={
                                                                switchStates[
                                                                    service
                                                                        .serviceId
                                                                ]
                                                            }
                                                            onChange={(
                                                                checked
                                                            ) => {
                                                                handleSwitchChange(
                                                                    service.serviceId,
                                                                    checked
                                                                )
                                                            }}
                                                        />
                                                        <span
                                                            className={
                                                                complianceStyles.serviceName
                                                            }
                                                        >
                                                            {service.serviceName !==
                                                            ''
                                                                ? service.serviceName
                                                                : 'Others'}
                                                        </span>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    name={`serviceAmount-${service.serviceId}`}
                                                >
                                                    <InputNumber
                                                        addonAfter={
                                                            <EuroOutlined />
                                                        }
                                                        className={
                                                            complianceStyles.serviccNumInput
                                                        }
                                                        disabled={
                                                            !switchStates[
                                                                service
                                                                    .serviceId
                                                            ]
                                                        }
                                                        value={
                                                            service.serviceAmount
                                                        }
                                                        defaultValue={
                                                            service.serviceAmount
                                                        }
                                                        onChange={(): void => {
                                                            calculateTotal()
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {service.serviceName === '' && (
                                                <div
                                                    className={
                                                        complianceStyles.textAreaWide
                                                    }
                                                >
                                                    <Form.Item name="OtherServiceDescription">
                                                        <TextArea
                                                            rows={3}
                                                            placeholder="Enter details for this service"
                                                            disabled={
                                                                !switchStates[
                                                                    service
                                                                        .serviceId
                                                                ]
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                            )}
                                        </Row>
                                    )
                                )}

                            <Row
                                gutter={[8, 24]}
                                className={complianceStyles.totalbox}
                            >
                                <Col span={14}>
                                    <Form.Item<ServiceData> name="subscriptionType">
                                        <Switch
                                            checked={isAnnualEnabled}
                                            onChange={handleAnnualSwitchChange}
                                        />
                                        <span
                                            className={
                                                complianceStyles.serviceName
                                            }
                                        >
                                            Annual
                                        </span>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item<ServiceData>
                                        name={
                                            isAnnualEnabled
                                                ? 'subscriptionTypeTotal'
                                                : undefined
                                        }
                                    >
                                        <InputNumber
                                            addonAfter={<EuroOutlined />}
                                            className={
                                                complianceStyles.serviccNumInput
                                            }
                                            disabled={!isAnnualEnabled}
                                            value={isAnnualEnabled ? total : 0}
                                            onChange={(value) => {
                                                if (value !== null) {
                                                    setAnnualTotal(value)
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[8, 32]}>
                                <Col span={14}>
                                    <Form.Item<ServiceData> name="subscriptionType">
                                        <Switch
                                            checked={isMonthlyEnabled}
                                            onChange={handleMonthlySwitchChange}
                                        />
                                        <span
                                            className={
                                                complianceStyles.serviceName
                                            }
                                        >
                                            Monthly
                                        </span>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item<ServiceData>
                                        name={
                                            isMonthlyEnabled
                                                ? 'subscriptionTypeTotal'
                                                : undefined
                                        }
                                    >
                                        <InputNumber
                                            addonAfter={<EuroOutlined />}
                                            className={
                                                complianceStyles.serviccNumInput
                                            }
                                            disabled={!isMonthlyEnabled}
                                            value={isMonthlyEnabled ? total : 0}
                                            defaultValue={
                                                isMonthlyEnabled ? total : 0
                                            }
                                            onChange={(value) => {
                                                if (value !== null) {
                                                    setMonthlyTotal(value)
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row
                                gutter={[8, 32]}
                                className={complianceStyles.serviceSwitch}
                            >
                                <Col span={14}>
                                    <Switch
                                        checked={isIncludeVATEnabled}
                                        onChange={handleIncludeVATSwitchChange}
                                    />
                                    <span
                                        className={complianceStyles.serviceName}
                                    >
                                        Include Vat (20%)
                                    </span>
                                </Col>
                                <Col span={10}>
                                    <Form.Item<ServiceData> name="vatAmount">
                                        <InputNumber
                                            addonAfter={<EuroOutlined />}
                                            className={
                                                complianceStyles.serviccNumInput
                                            }
                                            disabled
                                            defaultValue={vatAmount}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row
                                gutter={[8, 32]}
                                className={complianceStyles.totalbox}
                            >
                                <Col span={14}>
                                    <span>Net Total</span>
                                </Col>
                                <Col span={10}>
                                    <Form.Item<ServiceData> name="serviceAmountTotal">
                                        <InputNumber
                                            addonAfter={<EuroOutlined />}
                                            value={totalAmount}
                                            className={
                                                complianceStyles.serviccNumInput
                                            }
                                            disabled
                                            defaultValue={totalAmount}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[8, 24]} justify="start">
                                <Col span={8}>
                                    <Dropdown
                                        placement="top"
                                        arrow={{ pointAtCenter: true }}
                                        menu={{ items: itemcompliance }}
                                    >
                                        <Button type="primary">
                                            Compliance <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </Col>
                                <Col span={12}>
                                    <Dropdown
                                        placement="top"
                                        arrow={{ pointAtCenter: true }}
                                        menu={{ items: itemsQuotation }}
                                    >
                                        <Button type="primary">
                                            Quotation <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={8}>
                                <DocumentVerification
                                    clientCompanyVerificationViewList={
                                        clientCompanyVerificationViewList
                                    }
                                    docuswitchStates={docuswitchStates}
                                    handleSwitchChangeDocument={
                                        handleSwitchChangeDocument
                                    }
                                />
                            </Row>
                            <Row>
                                <ControlItems
                                    onbordingId={onbordingId}
                                    clientControlViewList={
                                        clientControlViewList
                                    }
                                    showDatePickerMap={showDatePickerMap}
                                    dateValues={dateValues}
                                    handleSwitchChangeControll={
                                        handleSwitchChangeControll
                                    }
                                    handleDateChange={(date, controllersId) => {
                                        handleDateChange(date, controllersId)
                                    }}
                                    openDeleteModal={openDeleteModal}
                                    // saveControlItemsData={saveControlItemsData}
                                />
                            </Row>
                        </Col>
                    </Row>
                    {ActivePremission('3000', PermissionType.SAVE) ? (
                        <Row gutter={16}>
                            <Col offset={22} span={2}>
                                <Button
                                    type="primary"
                                    onClick={handleSaveButtonClick}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <></>
                    )}
                </>
            </Form>
            <FullWidthModal
                modalStatus={servicemodelModalStatus}
                closeModal={closeServiceModalFunction}
                tittle="Do you wish to enable all active services?"
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={(): void => {
                    void handelEnableServiceClick(partnershipId)
                }}
            />
            <FullWidthModal
                modalStatus={CoplinceEmailmodelStatus}
                closeModal={closeCoplinceEmail}
                tittle="Do you wish to send this proposal?"
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={GenerateEmailss}
            />
            <FullWidthModal
                modalStatus={ComplinceSignmodelStatus}
                closeModal={closeCoplinceSign}
                tittle="Do you wish to send mail this proposal via signable?"
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={createSignableDoc}
            />
            <FullWidthModal
                modalStatus={QuptionEmailmodelStatus}
                closeModal={closeQuptionEmail}
                tittle="Do you wish to send this proposal?"
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={GenerateEmailss}
            />
            <Modal
                title="Signable History"
                onCancel={() => {
                    setViewModalVisible(false)
                }}
                visible={viewModalVisible}
                footer={null}
                width={'50%'}
            >
                <SignableHistory businessId={4} referenceId={partnershipId} />
            </Modal>
        </div>
    )
}

const mapStateToProps = (state: any): ComplaincepropView => {
    return {
        partnershipTaps: state.client.partnershipTaps,
    }
}
export default connect(mapStateToProps)(Compliance)
