import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Switch, Col, Row, Tooltip, Drawer } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import SearchInput from '../../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import FullWidthModal from '../../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import { MessageOutlined, EyeOutlined } from '@ant-design/icons'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import type {
    OnBoardingCopareateListStateInterface,
    OnBoardingIndividualListDataTypeInterface,
    OnBoardingindividualListInterface,
} from '../../../../Types/Client/OnboardingClients/OnboardingIndividual'
import {
    ActiveInactiveIndividual,
    DeleteIndividual,
    GetIndividualList,
} from '../../../../Services/OnBoardingIndividual'
import AddNewButton from '../../../../Components/AddNewButton'
import IndividualExpandView from './OnboardingIndividualExpand'
import { useNavigate } from 'react-router-dom'
import calculatePagination from '../../../../Utils/Pagination'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
import { GetChat } from '../../../../Services/Common'
import { type DataCHAT } from '../../../../Types/Client/ProspectClients/prospectClient'
import StaffCommunication from '../../../../Components/StaffChat'
export const IndividualItemDefaultData = {
    individualId: '0',
    name: '',
    tradingName: '',
    typeOfSelfEmployment: '',
    clientNumber: '',
    activestatus: '',
    serviceIds: '',
    mobilenumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    defaultUser: '',
    defaultAssigneeUser: '',
}

const IndividualList = (
    props: OnBoardingindividualListInterface
): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        onboardingindividualList,
        onboardingindividualListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [individualId, setIndividualId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')

    // const [chatModalOpen, setChatModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (Number(individualId) !== 0) {
            void GetChat(
                dispatch,
                6,
                Number(individualId),
                3,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [individualId])

    const openChatModal = (individualId: string): void => {
        // setSelectedCorporateId(corporateid)
        // setChatModalOpen(true)
        setIndividualId(individualId)
        setOpen(true)
    }
    const onClose = (): void => {
        setOpen(false)
    }

    useEffect(() => {
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetIndividualList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: onboardingindividualListCount,
            },
        })
    }, [onboardingindividualListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])
    const createIndividual = (id: string): void => {
        const individualData = onboardingindividualList?.find(
            (item) => item.individualId === id
        )
        navigate(`/clients/registered-clients/individual-new`, {
            state: { individualData },
        })
    }

    const viewIndividualDetails = (id: string): void => {
        const individualData = onboardingindividualList?.find(
            (item) => item.individualId === id
        )
        const onbordingId = 1
        navigate(`/clients/registered-clients/individual-view`, {
            state: { individualData, onbordingId },
        })
    }

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        onboardingindividualListCount,
        itemsPerPage
    )

    const columns: ColumnsType<OnBoardingIndividualListDataTypeInterface> = [
        {
            title: 'Client No',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Trading Name',
            dataIndex: 'tradingName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: ' Employment Type',
            dataIndex: 'typeOfSelfEmployment',
        },
        {
            title: 'Service',
            dataIndex: 'serviceIds',
        },
        {
            title: 'Action',
            key: 'action1',
            render: (_, record: OnBoardingIndividualListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Staff Communication">
                            <MessageOutlined
                                onClick={() => {
                                    openChatModal(record?.individualId)
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="View Details">
                            <EyeOutlined
                                className={commonStyles.viewIcon}
                                onClick={() => {
                                    viewIndividualDetails(record?.individualId)
                                }}
                            />
                        </Tooltip>

                        <Tooltip
                            title={
                                record.activestatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activestatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.individualId,
                                        record.activestatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.DELETE) ? (
                            <Tooltip title="Delete Induvial">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.individualId
                                        )
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<OnBoardingIndividualListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'tradingName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }
    // const changeStaffCommunicatinStatus = (): void => {
    //     setChatModalOpen(!chatModalOpen)
    // }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let individualId = ''
        if (!deleteModalStatus) {
            individualId = id
        }
        setIndividualId(individualId)
    }

    const deleteIndividual = (): void => {
        DeleteIndividual(dispatch, individualId, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let individualId = ''
        if (!activeInactiveModalStatus) {
            individualId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setIndividualId(individualId)
    }

    const updateActiveInactiveIndividual = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveIndividual(dispatch, individualId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const expandableData = (
        record: OnBoardingIndividualListDataTypeInterface
    ): JSX.Element => {
        return <IndividualExpandView IndividualData={record} />
    }

    return (
        <Card className={commonStyles.card}>
            <Row gutter={16} align="middle">
                <div className={commonStyles.cardContainer}>
                    <Col span={16}>
                        <SearchInput
                            placeHolder={'Search Trading Name by keywords'}
                            onChange={onChangeText}
                            onSearch={onChangeFilter}
                            width="478"
                        />
                    </Col>
                    <Col span={4}>
                        <AddNewButton
                            name="Add New"
                            clickAction={() => {
                                createIndividual('')
                            }}
                        />
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={onboardingindividualList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={deleteModalFunction}
                tittle="You are about to delete this Individual. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteIndividual}
            />
            <FullWidthModal
                modalStatus={activeInactiveModalStatus}
                closeModal={() => {
                    activeInactiveModalFunction('', '')
                }}
                tittle={`You are about to ${activeInactiveStatusLabel} this Individual. Click 'Yes' to continue.`}
                smallTittle=""
                yesButtonFunction={updateActiveInactiveIndividual}
            />

            {/* <Modal
                title="Staff Comm"
                open={chatModalOpen}
                onCancel={changeStaffCommunicatinStatus}
                footer={null}
                width={'50%'}
            >
                {selectedIndividualId != null && (
                    <IndividualChat
                        individualId={parseInt(selectedIndividualId, 10)}
                        onCloseModel={changeStaffCommunicatinStatus}
                    />
                )}
            </Modal> */}
            <Drawer
                title={ChatDatas?.clientName}
                width={550}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication
                    refernceId={Number(individualId)}
                    messageTypeId={6}
                    businessId={3}
                />
            </Drawer>
        </Card>
    )
}

const mapStateToProps = (state: any): OnBoardingCopareateListStateInterface => {
    return {
        onboardingindividualList: state.client.onboardingindividualList,
        onboardingindividualListCount:
            state.client.onboardingindividualListCount,
        serviceId: state.initial.serviceList,
    }
}

export default connect(mapStateToProps)(IndividualList)
