import axios from '../Config/AxiosConfig'
import type { Dispatch } from '@reduxjs/toolkit'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import { dashboard, itdashboard } from '../Reducer/DashboardReducer'

export const GetDashboard = async (
    dispatch: Dispatch,
    clientCategoryId: number,
    flag: number
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    await axios
        .get(`api/Dashboard/GetDashboard/${clientCategoryId}/${flag}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(dashboard(response.data.data))
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.error.message)
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SetApprovalStatus = async (
    dispatch: Dispatch,
    statue: number,
    approvalRequestId: number,
    approvalFlag: number,
    approvalComment: string
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    await axios
        .post(
            statue === 1
                ? `api/Employee/SetLeaveApprovalStatus`
                : statue === 2
                  ? `api/Employee/SetPermissionApprovalStatus`
                  : `api/Timesheet/SetTimesheetApprovalStatus`,
            {
                approvalRequestId,
                approvalFlag,
                approvalComment,
            }
        )
        .then((response) => {
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.error.message)
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetITDashboard = async (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))

    await axios
        .get(`/api/Dashboard/GetDashboardITDepartment/0`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(itdashboard(response.data.data))
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.error.message)
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
