import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Collapse, Switch, Input, Button } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { SaveClientCompanySupplier } from '../../../../../Services/Corporate'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../../../Utils/Common.less'
import type {
    SupplierDetailsFormData,
    SupplierInfoInterFace,
} from '../../../../../Types/Supplier'
import { SuccessMessage } from '../../../../../Utils/Notification'
import ActivePremission from '../../../../../Utils/premissins'
import { PermissionType } from '../../../../../Types/Enum/PermissionType'

const SupplierInfo = (props: SupplierInfoInterFace): React.ReactElement => {
    const { supplierInfoList, corporateId } = props
    const { Panel } = Collapse
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [enabledSwitchIndices, setEnabledSwitchIndices] = useState<number[]>(
        []
    )

    const initialValues: Record<string, any> = {}

    const handleSwitchChange = (index: number): void => {
        if (enabledSwitchIndices.includes(index)) {
            setEnabledSwitchIndices(
                enabledSwitchIndices?.filter((i) => i !== index)
            )
        } else {
            setEnabledSwitchIndices([...enabledSwitchIndices, index])
            //  initialValues[`details[${index}].status`] = 1
        }
    }

    useEffect(() => {
        if (supplierInfoList !== null) {
            const initialSwitchIndices = Object.keys(supplierInfoList)
                ?.filter(
                    (key) =>
                        supplierInfoList[key]?.companyType !== 0 &&
                        supplierInfoList[key]?.referenceId !== 0
                )
                ?.map((key) => parseInt(key))

            setEnabledSwitchIndices(initialSwitchIndices)
        }
    }, [supplierInfoList])

    const handleSave = (values: any): void => {
        saveSupplierData(values)
    }

    const saveSupplierData = (values: {
        details: SupplierDetailsFormData[]
    }): void => {
        const formValues = form.getFieldsValue()
        const updatedDetails = formValues.details?.map(
            (detail: SupplierDetailsFormData, index: number) => ({
                ...detail,
                companyType: 1,
                referenceId: corporateId,
            })
        )

        const selectedDetails = updatedDetails?.filter(
            (detail: any, index: number) => enabledSwitchIndices.includes(index)
        )

        selectedDetails.forEach((updatedDetail: SupplierDetailsFormData) => {
            SaveClientCompanySupplier(dispatch, updatedDetail)
                .then((result) => {
                    if (result !== null && result !== undefined) {
                        /* handle success */
                    }
                })
                .catch(() => {
                    /* handle error */
                })
        })
        SuccessMessage('Supplier Information Save Successfully')
    }

    return (
        <Form
            name="complex-form"
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 16 }}
            layout={'vertical'}
            autoComplete="off"
            initialValues={initialValues}
            form={form}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Collapse>
                        <Panel header="Supplier Information" key="1">
                            {supplierInfoList?.length > 0 &&
                                supplierInfoList?.map(
                                    (supplier: any, index: number) => (
                                        <Row key={index}>
                                            <Col span={12}>
                                                <Row>
                                                    <div
                                                        className={
                                                            commonStyles.SupplierInfoList
                                                        }
                                                    >
                                                        <Switch
                                                            checked={enabledSwitchIndices?.includes(
                                                                index
                                                            )}
                                                            onChange={() => {
                                                                handleSwitchChange(
                                                                    index
                                                                )
                                                            }}
                                                            className={
                                                                commonStyles.SupplierInfoSwitch
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            commonStyles?.SupplierInfoList
                                                        }
                                                    >
                                                        {supplier.supplierName}
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    {enabledSwitchIndices.includes(
                                                        index
                                                    ) && (
                                                        <>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'supplierId',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.supplierId
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'supplierName',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.supplierName
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'clientCompanySupplierId',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.clientCompanySupplierId
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'companyType',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.companyType
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'status',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.status
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[
                                                                    'details',
                                                                    index,
                                                                    'referenceId',
                                                                ]}
                                                                hidden={true}
                                                                initialValue={
                                                                    supplier.referenceId
                                                                }
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <div
                                                                className={
                                                                    commonStyles.SupplierInfoList
                                                                }
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        'details',
                                                                        index,
                                                                        'userName',
                                                                    ]}
                                                                    initialValue={
                                                                        supplier.userName
                                                                    }
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="User Name"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={
                                                                    commonStyles.SupplierInfoList
                                                                }
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        'details',
                                                                        index,
                                                                        'password',
                                                                    ]}
                                                                    initialValue={
                                                                        supplier.password
                                                                    }
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Password"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={
                                                                    commonStyles.SupplierInfoList
                                                                }
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        'details',
                                                                        index,
                                                                        'consumerNo',
                                                                    ]}
                                                                    initialValue={
                                                                        supplier.consumerNo
                                                                    }
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Customer"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div
                                                                className={
                                                                    commonStyles.SupplierInfoList
                                                                }
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        'details',
                                                                        index,
                                                                        'url',
                                                                    ]}
                                                                    initialValue={
                                                                        supplier.url
                                                                    }
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="URL"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                )}
                            {ActivePremission('3000', PermissionType.SAVE) ? (
                                <Row gutter={16}>
                                    <Col offset={20} span={2}>
                                        <Button
                                            type="primary"
                                            onClick={handleSave}
                                        >
                                            <SaveOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </Form>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        supplierInfoList: state.client.corporateTaps,
    }
}

export default connect(mapStateToProps)(SupplierInfo)
