import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Card,
    Table,
    Select,
    Space,
    DatePicker,
    type DatePickerProps,
    type MenuProps,
    Dropdown,
    Modal,
    type SelectProps,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type InvoiceDetailsListInterface,
    type createInvoicePageProp,
    type OptionList,
    type InvoiceHeader,
    type InvoiceBody,
    type InvoiceDetail,
    type InvoiceDetailView,
} from '../../../../Types/Invoice/Invoicing'
import {
    GetClientAddressData,
    GetInvoicePreview,
    GetPaymentMode,
    GetPaymentOption,
    SaveInvoiceDetails,
} from '../../../../Services/Invoice'
import { connect, useDispatch } from 'react-redux'
import { GetUserList } from '../../../../Services/User'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'
import ComPaidInvoice from './paidInvoice'
import invoive from '../Invoice.less'
import EditabeRowTable from '../edittableTdTh'
import { GetServiceDropDownList } from '../../../../Services/Corporate'
import { ErrorMessage } from '../../../../Utils/Notification'
import axios from '../../../../Config/AxiosConfig'
import { type TogilleAddress } from '../../../../Types/Client/RegisteredClients/Corporate'
import moment from 'moment'
const ViewInvoice = (props: createInvoicePageProp): React.ReactElement => {
    const {
        userList,
        paymentOptionList,
        paymentModeList,
        serviceDropDownList,
    } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { InvoiceId, invoicename, invoiceSaveViewStatus } = location.state
    const [form] = Form.useForm()
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedpaymentOption, setSelectedpaymentOption] = useState('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [paymentoptions, setPymentOptions] = useState<OptionList[]>(
        [] as OptionList[]
    )
    const [selectedDueDate, setSelectedDueDate] = useState<string>('')
    const [selectedInvoiceDate, setSelectedInvoiceDate] = useState<string>('')
    const [InvoiceHeader, setInvoiceHeader] = useState<InvoiceHeader>()
    const [PidInvoicemodel, setPidInvoicemodel] = useState(false)
    const [subTotal, setSubTotal] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [netTotal, setNetTotal] = useState(0)
    const [tax, setTaxTotal] = useState(0)
    const [tabledata, setTabledata] = useState<InvoiceBody[]>([])
    const [data, setData] = useState<SelectProps['options']>([])
    const [clientNumber, setClientNumber] = useState('')
    const [businessType, setBusinessType] = useState(0)
    const [invoicFor, setInvoicFor] = useState(0)

    useEffect(() => {
        const GetInvoicePreviews = async (): Promise<void> => {
            const previewDatas = await GetInvoicePreview(dispatch, InvoiceId)
            const invoiceHeader = previewDatas?.data?.invoiceHeader
            setInvoiceHeader(invoiceHeader)
            form.setFieldsValue(invoiceHeader)
            setSelectedInvoiceDate(invoiceHeader?.invoiceDate)
            setSelectedDueDate(invoiceHeader?.paymentDueDate)
            setSearchValue(invoiceHeader?.clientName)
        }
        void GetInvoicePreviews()
    }, [InvoiceId])
    console.log('selectedpaymentOption', selectedpaymentOption)
    useEffect(() => {
        GetUserList(dispatch)
        GetPaymentMode(dispatch)
        GetServiceDropDownList(dispatch)
        GetPaymentOption(dispatch)
    }, [])
    useEffect(() => {
        const paymetOptionId = paymentoptions?.find(
            (pament) => pament.label === InvoiceHeader?.paymentOptionName
        )
        form.setFieldValue('paymetOptionId', paymetOptionId?.value)
        if (paymetOptionId !== undefined) {
            setSelectedpaymentOption(paymetOptionId?.value.toString())
        }

        console.log('paymetOptionId', paymetOptionId)
    }, [InvoiceHeader])
    useEffect(() => {
        form.setFieldValue('duedate', selectedDueDate)
        form.setFieldValue('invoicedate', selectedInvoiceDate)
    }, [selectedDueDate, selectedInvoiceDate])

    const onChangeDue = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()

            setSelectedDueDate(isoDate)
        }
    }

    const onChangeInvoiceDate = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedInvoiceDate(isoDate)
        }
    }

    const dataSource = [
        {
            key: '1',
            displayname: <b>Sub Total</b>,
            amount: subTotal === 0 ? InvoiceHeader?.subTotal : subTotal,
        },
        {
            key: '2',
            displayname: <b>Discount</b>,
            amount: discount === 0 ? InvoiceHeader?.discountTotal : discount,
        },
        {
            key: '3',
            displayname: <b>Net Total</b>,
            amount: netTotal === 0 ? InvoiceHeader?.netTotal : netTotal,
        },
        {
            key: '4',
            displayname: <b>Tax</b>,
            amount: tax === 0 ? InvoiceHeader?.taxTotal : tax,
        },
        {
            key: '5',
            displayname: <b>Invoice Amount ( £ )</b>,
            amount:
                netTotal === 0
                    ? (InvoiceHeader?.taxTotal ?? 0) +
                      (InvoiceHeader?.netTotal ?? 0)
                    : netTotal + tax,
        },
    ]

    const columns = [
        { title: '', dataIndex: 'displayname', key: 'name' },
        { title: '', dataIndex: 'amount', key: 'amount' },
    ]

    const handleClientChange = (value: string): void => {
        setClientNumber(value)
        const splitValues = value?.split('-')

        if (splitValues !== null && splitValues?.length > 0) {
            const [firstValueStr, secondValueStr] = splitValues
            const firstValue: number = parseInt(firstValueStr, 10)
            const secondValue: number = parseInt(secondValueStr, 10)

            setBusinessType(firstValue)
            setInvoicFor(secondValue)

            console.log('firstValue', firstValue)
            console.log('secondValue', secondValue)
        }
    }

    const handlePaymentOptionChange = (value: string): void => {
        setSelectedpaymentOption(value)
    }

    const onInvoicePreviewClick = (): void => {
        navigate(`/invoicing/invoic-status/previewInvoice-invoice`, {
            state: { InvoiceId },
        })
    }

    const openPaidModel = (): void => {
        setPidInvoicemodel(!PidInvoicemodel)
    }

    const changePaidModalStatus = (): void => {
        setPidInvoicemodel(!PidInvoicemodel)
    }
    const itemInvoice: MenuProps['items'] = [
        {
            key: '1',
            label: 'Preview Of Invoice',
            onClick: onInvoicePreviewClick,
        },
        {
            key: '2',
            label: 'Paid Invoice',
            onClick: openPaidModel,
        },
        {
            key: '3',
            label: `Send on ${InvoiceHeader?.invoiceDate}`,
        },
    ]

    const GetStandardListingTwo = async (search: string): Promise<void> => {
        setLoading(true)
        await axios
            .get(`api/GetStandardListingTwo/1/${search}`)
            .then((response: any) => {
                if (response.status === 200) {
                    const clients = response?.data?.data

                    const newData = clients?.map((client: any) => {
                        return {
                            label: client.displayName,
                            value: client.id,
                            desc: (
                                <div>
                                    <div
                                        className={client.clintDropdownListDiv}
                                    >
                                        <span>{client.displayName}</span>
                                    </div>
                                    <div>
                                        <span>
                                            {client.additionalDataTwo}-
                                            {client.additionalDataOne}
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            className={
                                                client.clientDropdownListSpan
                                            }
                                        >
                                            Name-{client.additionalDataThree}
                                        </span>
                                        <span
                                            className={
                                                client.clientDropdownListSpan2
                                            }
                                        ></span>
                                        <span
                                            className={
                                                client.clientDropdownListSpan
                                            }
                                        >
                                            ContactNo-
                                            {client.additionalDataFour}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Email-{client.additionalDataFive}
                                        </span>
                                    </div>
                                </div>
                            ),
                        }
                    })
                    setData(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch((error: any) => {
                setLoading(false)
                ErrorMessage(error)
            })
    }

    useEffect(() => {
        if (
            searchValue !== null &&
            searchValue !== undefined &&
            searchValue !== '' &&
            searchValue?.length > 2
        ) {
            void GetStandardListingTwo(searchValue)
        }
    }, [searchValue])

    useEffect(() => {
        if (paymentOptionList !== undefined) {
            setPymentOptions(
                paymentOptionList?.map((client) => {
                    return {
                        label: client.displayName,
                        value: client.id,
                        id: client.additionalDataFour,
                        desc: (
                            <div>
                                <div className={invoive.clintDropdownListDiv}>
                                    <img
                                        className={invoive.clintDropdownListImg}
                                        src={client.displayImageURL}
                                        alt="User"
                                    />
                                    <span>{client.displayName}</span>
                                </div>
                                <div>
                                    <span>
                                        {client.additionalDataTwo}-
                                        {client.additionalDataOne}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className={
                                            invoive.clientDropdownListSpan
                                        }
                                    >
                                        Name-{client.additionalDataThree}
                                    </span>
                                    <span
                                        className={
                                            invoive.clientDropdownListSpan2
                                        }
                                    ></span>
                                    <span
                                        className={
                                            invoive.clientDropdownListSpan
                                        }
                                    >
                                        ContactNo-{client.additionalDataFour}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Email-{client.additionalDataFive}
                                    </span>
                                </div>
                            </div>
                        ),
                    }
                })
            )
        }
    }, [paymentOptionList])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onSave = (): void => {
        void onSaveEmail()
    }
    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()
            const setSelectedClient = data?.find(
                (client) => client.value === formData?.clientName
            )
            const preparedById = userList.find(
                (user) => user.label === formData?.preparedBy
            )

            const paymentoptionsId = paymentoptions.find(
                (pament) => pament.value === formData.paymetOptionId
            )

            const approvedById = userList.find(
                (user) => user.label === formData?.approvedBy
            )

            const clienid = data?.find(
                (d) => d.label === InvoiceHeader?.clientName
            )
            const clientIdess = clienid?.value?.toString()
            const newData =
                clientNumber !== ''
                    ? clientNumber?.split('-')
                    : clientIdess?.split('-')
            const tabledataWithoutLastItem = tabledata.slice(0, -1)
            let InvoiceDetailList: InvoiceDetail[] = []
            let InvoiceDetailViewList: InvoiceDetailView[] = []

            if (
                tabledataWithoutLastItem !== null &&
                tabledataWithoutLastItem.length > 0
            ) {
                InvoiceDetailList = tabledataWithoutLastItem.map((data) => ({
                    invoiceId: data?.invoiceId ?? 0,
                    lineDescription: data?.lineDescription ?? '',
                    quantity: 0,
                    unitPrice: data?.grossTotal ?? 0,
                    grossTotal: data?.grossTotal ?? 0,
                    discountType: data?.discountType ?? 1,
                    discountValue: data?.discountValue ?? 0,
                    discount: 0,
                    totalAfterDiscount:
                        data?.totalAfterDiscount ??
                        data?.grossTotal - data?.discountValue ??
                        0,
                    serviceId:
                        serviceDropDownList?.find(
                            (service) => service.label === data?.serviceName
                        )?.value ?? 0,
                    taxApplied: 0,
                    tax: data?.tax ?? 0,
                    netAmount: data?.netAmount ?? 0,
                }))

                InvoiceDetailViewList = tabledataWithoutLastItem.map(
                    (data) => ({
                        invoiceId: data?.invoiceId ?? 0,
                        lineDescription: data?.lineDescription ?? '',
                        quantity: 0,
                        unitPrice: data?.grossTotal ?? 0,
                        grossTotal: data?.grossTotal ?? 0,
                        discountType: data?.discountType ?? 1,
                        discountValue: data?.discountValue ?? 0,
                        discount: 0,
                        totalAfterDiscount:
                            data?.totalAfterDiscount ??
                            data?.grossTotal - data?.discountValue ??
                            0,
                        serviceId:
                            serviceDropDownList?.find(
                                (service) => service.label === data?.serviceName
                            )?.value ?? 0,
                        taxApplied: 0,
                        tax: data?.tax ?? 0,
                        netAmount: data?.netAmount ?? 0,
                        invoiceDetailId: data?.invoiceDetailId ?? 0,
                        serviceName: data?.serviceName ?? '',
                        description: data?.description ?? '',
                    })
                )
            } else {
                if (InvoiceHeader?.invoiceBodyList != null) {
                    InvoiceDetailList = InvoiceHeader?.invoiceBodyList.map(
                        (data) => ({
                            invoiceId: data?.invoiceId ?? 0,
                            lineDescription: data?.lineDescription ?? '',
                            quantity: 0,
                            unitPrice: data?.grossTotal ?? 0,
                            grossTotal: data?.grossTotal ?? 0,
                            discountType: data?.discountType ?? 1,
                            discountValue: data?.discountValue ?? 0,
                            discount: 0,
                            totalAfterDiscount:
                                data?.totalAfterDiscount ??
                                data?.grossTotal - data?.discountValue,
                            serviceId:
                                serviceDropDownList?.find(
                                    (service) =>
                                        service.label === data?.serviceName
                                )?.value ?? 0,
                            taxApplied: 0,
                            tax: data?.tax ?? 0,
                            netAmount: data?.netAmount ?? 0,
                        })
                    )

                    InvoiceDetailViewList = InvoiceHeader?.invoiceBodyList.map(
                        (data) => ({
                            invoiceId: data?.invoiceId ?? 0,
                            lineDescription: data?.lineDescription ?? '',
                            quantity: 0,
                            unitPrice: data?.grossTotal ?? 0,
                            grossTotal: data?.grossTotal ?? 0,
                            discountType: data?.discountType ?? 1,
                            discountValue: data?.discountValue ?? 0,
                            discount: 0,
                            totalAfterDiscount:
                                data?.totalAfterDiscount ??
                                data?.grossTotal - data?.discountValue,
                            serviceId:
                                serviceDropDownList?.find(
                                    (service) =>
                                        service.label === data?.serviceName
                                )?.value ?? 0,
                            taxApplied: 0,
                            tax: data?.tax ?? 0,
                            netAmount: data?.netAmount ?? 0,
                            invoiceDetailId: data?.invoiceDetailId ?? 0,
                            serviceName: data?.serviceName ?? '',
                            description: data?.description ?? '',
                        })
                    )
                }
            }

            const dataTosave = {
                invoiceId: InvoiceHeader?.invoiceId ?? 0,
                invoiceNo: InvoiceHeader?.invoiceNo ?? '',
                invoiceCategory: 0,
                businessType,
                invoiceFor: invoicFor,
                invoiceForDescription: formData?.invoiceForDescription ?? '',
                invoiceDate:
                    moment(selectedInvoiceDate).format('DD/MM/YYYY') ?? '',
                paymentDueDate:
                    moment(selectedDueDate).format('DD/MM/YYYY') ?? '',
                total:
                    InvoiceHeader?.total !== undefined
                        ? InvoiceHeader?.total + tax + netTotal
                        : tax + netTotal,
                subTotal:
                    InvoiceHeader?.subTotal !== undefined
                        ? InvoiceHeader?.subTotal + subTotal
                        : subTotal,
                discountTotal:
                    InvoiceHeader?.discountTotal !== undefined
                        ? InvoiceHeader?.subTotal + discount
                        : discount,
                taxTotal:
                    InvoiceHeader?.taxTotal !== undefined
                        ? InvoiceHeader?.subTotal + tax
                        : tax,
                netTotal:
                    InvoiceHeader?.netTotal !== undefined
                        ? InvoiceHeader?.subTotal + netTotal
                        : netTotal,
                preparedBy: preparedById?.value ?? 0,
                paymentNotifyTo: 0,
                paidTo: 0,
                paidToDescription: formData?.paidToDescription ?? '',
                approvedBy: approvedById?.value ?? 0,
                invoiceStatus: 0,
                paymentModeId: formData?.paymentModeId ?? 0,
                paymentOptionId: Number(formData.paymetOptionId) ?? 0,
                remarks: formData?.remarks ?? '',
                clientId: newData !== undefined ? newData[1] : '0',
                clientName:
                    setSelectedClient?.label?.toString() ??
                    InvoiceHeader?.clientName ??
                    '',
                paymentOptionName: paymentoptionsId?.label ?? '',
                acc_InvoiceDetailList: InvoiceDetailList ?? null,
                acc_InvoiceDetailViewList: InvoiceDetailViewList ?? null,
                isSent: 0,
                sentDate: '',
                serviceProcessId: 0,
                isPaid: 0,
                paidDate: '',
                paidRemarks: '',
                accountName: InvoiceHeader?.accountName ?? '',
                accountNo: InvoiceHeader?.accountNo ?? '',
                branch: InvoiceHeader?.branch ?? '',
                swiftCode: InvoiceHeader?.swiftCode ?? '',
                iban: '',
            }
            console.log('dataTosave', businessType, invoicFor)
            await SaveInvoiceDetails(dispatch, dataTosave)
        } catch (error) {
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        if (
            clientNumber?.length > 0 &&
            clientNumber !== null &&
            clientNumber !== ''
        ) {
            const clientNumberData = clientNumber?.split('-')
            const businessId = clientNumberData[0]
            const referenceId = clientNumberData[1]
            const addressid = businessId === '1' ? 3 : 2
            void GetClientAddressData(
                dispatch,
                businessId,
                referenceId,
                addressid,
                (addressData: TogilleAddress) => {
                    form.setFieldValue(
                        'invoiceForDescription',
                        `${addressData?.addressLineOne}\n${addressData?.addressLineTwo}\n${addressData?.town}\n${addressData?.postCode}\n${addressData?.country}`
                    )
                    form.setFieldValue(
                        'paidToDescription',
                        `${addressData?.addressLineOne}\n${addressData?.addressLineTwo}\n${addressData?.town}\n${addressData?.postCode}\n${addressData?.country}`
                    )
                }
            )
        }
    }, [clientNumber])
    return (
        <Card title={invoicename} className={commonStyles.card}>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    size="small"
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client" name="clientName">
                                <Select
                                    loading={isLoading}
                                    showSearch
                                    placeholder="To filter the clients by number /name"
                                    onChange={(value) => {
                                        handleClientChange(value)
                                    }}
                                    onSearch={(value) => {
                                        setSearchValue(value)
                                    }}
                                    filterOption={false}
                                    allowClear
                                    onClear={() => {
                                        setData([])
                                    }}
                                    optionLabelProp="label"
                                    options={data}
                                    optionRender={(option) => (
                                        <>{option.data.desc}</>
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Raised by" name="preparedBy">
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={userList}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Invoice Date" name="invoicedate">
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            onChangeInvoiceDate(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            selectedInvoiceDate?.length > 0
                                                ? dayjs(selectedInvoiceDate)
                                                : null
                                        }
                                        className={commonStyles.dateWidth}
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Due Date" name="duedate">
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            onChangeDue(date, dateString)
                                        }}
                                        value={
                                            selectedDueDate?.length > 0
                                                ? dayjs(selectedDueDate)
                                                : null
                                        }
                                        className={commonStyles.dateWidth}
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Invoice To"
                                name="invoiceForDescription"
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Paid By" name="paidToDescription">
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24} className={invoive.editrowTableMargin}>
                            <EditabeRowTable
                                invoiceBodyList={InvoiceHeader?.invoiceBodyList}
                                setTaxTotal={setTaxTotal}
                                setSubTotal={setSubTotal}
                                setDiscount={setDiscount}
                                setNetTotal={setNetTotal}
                                setTabledata={setTabledata}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[48, 8]}>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Payment Mode"
                                        name="paymentModeName"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="Please select"
                                            onChange={() => {}}
                                            optionFilterProp="children"
                                            value={
                                                InvoiceHeader?.paymentModeName
                                            }
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            options={paymentModeList}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Payment Option"
                                        name="paymetOptionId"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="Please select"
                                            onChange={handlePaymentOptionChange}
                                            optionFilterProp="children"
                                            value={
                                                InvoiceHeader?.paymentOptionName
                                            }
                                            optionLabelProp="label"
                                            options={paymentoptions}
                                            optionRender={(option) => (
                                                <>{option.data?.desc}</>
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Approved by"
                                        name="approvedBy"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="Please select"
                                            onChange={() => {}}
                                            optionFilterProp="children"
                                            value={InvoiceHeader?.approvedBy}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            options={userList}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Remarks" name="remarks">
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <div>
                                <Table
                                    dataSource={dataSource}
                                    columns={columns}
                                    pagination={false}
                                    showHeader={false}
                                    scroll={{ x: 12 }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={3} offset={1}>
                            <Dropdown
                                placement="top"
                                arrow={{ pointAtCenter: true }}
                                menu={{ items: itemInvoice }}
                            >
                                <Button type="primary" size="middle" block>
                                    Invoice
                                </Button>
                            </Dropdown>
                        </Col>
                        {invoiceSaveViewStatus === true ? (
                            <Col span={2} offset={17}>
                                <Button
                                    type="primary"
                                    size="middle"
                                    block
                                    onClick={onSave}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                </Form>
            </div>
            <Modal
                footer={null}
                onCancel={changePaidModalStatus}
                width={'35%'}
                title={'Paid Invoice'}
                open={PidInvoicemodel}
            >
                <ComPaidInvoice
                    invoiceIds={InvoiceId}
                    changePaidModalStatus={changePaidModalStatus}
                />
            </Modal>
        </Card>
    )
}

const mapStateToProps = (state: any): InvoiceDetailsListInterface => {
    return {
        clientDropDownList: state.invoice.clientDropDownList,
        userList: state.user.userList,
        serviceDropDownList: state.initial.serviceDropDownList,
        paymentOptionList: state.invoice.paymentOptionList,
        paymentModeList: state.invoice.paymentModeList,
    }
}

export default connect(mapStateToProps)(ViewInvoice)
