import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Select,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import {
    type ManageUserDetailsFormDataInterface,
    type ManageUserDetailsFormInterface,
    type UserINFOFormStateInterface,
} from '../../../Types/ManageUser'
import { UserInformationDefaultData } from './userInfoList'
import { GetCountryList } from '../../../Services/Common'
import S3Upload from '../../../Components/S3Upload'
import {
    GetDepartmentDropDownList,
    GetDesignationDropDownList,
    SaveUserInfo,
} from '../../../Services/ManageUser'
import { GetUserList } from '../../../Services/User'
import dayjs from 'dayjs'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const UserInfoForm = (
    props: ManageUserDetailsFormInterface
): React.ReactElement => {
    const {
        onSave,
        editData,
        countryList,
        userList,
        categoryDropDownList,
        departmentDropDownList,
        designationDropDownList,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [clientCategoryId, setClientCategoryId] = useState<number | null>(
        null
    )
    const [selectedLabelcategory, setSelectedLabelcategory] =
        useState<string>('')
    const [departmentId, setDepartmentId] = useState<number | null>(null)
    const [depatmetlable, setSelectedLabeldepartment] = useState<string>('')
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [selectedLabelManager, setSelectedLabelManager] = useState<string>('')

    const [selectedLabelUsergroup, setSelectedLabelUsergroup] =
        useState<string>('')
    const [selectedLabelDesignation, setSelectedLabelDesignation] =
        useState<string>('')

    useEffect(() => {
        GetCountryList(dispatch)
        GetUserList(dispatch)
        GetCategoryDropDownList(dispatch)
    }, [])

    useEffect(() => {
        if (clientCategoryId !== null) {
            GetDepartmentDropDownList(dispatch, clientCategoryId)
        }
    }, [clientCategoryId, editData])

    useEffect(() => {
        if (departmentId !== null) {
            GetDesignationDropDownList(dispatch, departmentId)
        }
    }, [departmentId, editData])

    useEffect(() => {
        setSelectedDate(editData.dateOfBirth)
    }, [editData.dateOfBirth])

    useEffect(() => {
        if (editData.userId != null) {
            const clientCategoryData = categoryDropDownList.find(
                (data: ListFrontEndInterface) =>
                    data.label === editData.clientCategoryName
            )

            setClientCategoryId(clientCategoryData?.value ?? null)
        }
    }, [editData.userId, categoryDropDownList])

    useEffect(() => {
        if (editData.userId != null && departmentDropDownList?.length > 0) {
            const departmentData = departmentDropDownList.find(
                (data: ListFrontEndInterface) =>
                    data.label === editData.departmentName
            )

            setDepartmentId(departmentData?.value ?? null)
        }
    }, [editData.userId, departmentDropDownList])

    useEffect(() => {
        if (
            editData.userId != null &&
            departmentDropDownList?.length > 0 &&
            designationDropDownList?.length > 0
        ) {
            const designationData = designationDropDownList.find(
                (data: ListFrontEndInterface) =>
                    data.label === editData.designationName
            )

            const newData = {
                ...editData,
                clientCategoryId: clientCategoryId ?? null,
                departmentId: departmentId ?? null,
                designationId: designationData?.value ?? null,
            }
            form.setFieldsValue(newData)
        }
    }, [editData.userId, designationDropDownList])

    useEffect(() => {
        if (editData.userId != null) {
            const countryData = countryList.find(
                (data: ListFrontEndInterface) => data.label === editData.country
            )

            const newData = {
                ...editData,
                countryName: countryData?.value ?? '',
            }
            form.setFieldsValue(newData)
        }
    }, [editData.userId, countryList])

    const resetForm = (): void => {
        form.setFieldsValue({
            UserInformationDefaultData,
            clientCategoryId: null,
            departmentName: undefined,
            dateOfBirth: '',
        })
    }

    useEffect(() => {
        form.setFieldValue('dateOfBirth', selectedDate)
    }, [selectedDate])

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const handleCategoryChange = (option: ListFrontEndInterface): void => {
        setClientCategoryId(form.getFieldValue('clientCategoryId'))
        setSelectedLabelcategory(option.label)
    }

    const handleDepartmentChange = (option: ListFrontEndInterface): void => {
        setDepartmentId(form.getFieldValue('departmentId'))
        setSelectedLabeldepartment(option.label)
    }
    const handleManagerChange = (option: ListFrontEndInterface): void => {
        setSelectedLabelManager(option.label)
    }

    const handleDesignationchanage = (option: ListFrontEndInterface): void => {
        setSelectedLabelDesignation(option.label)
    }

    const handleUserGroupChange = (option: ListFrontEndInterface): void => {
        setSelectedLabelUsergroup(option.label)
    }
    const setFormData = (name: string, location: string): void => {
        form.setFieldValue(name, location)
    }

    const saveuserInformationData = (): void => {
        void saveuserInformation()
    }
    const saveuserInformation = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            // const managerName = userList.find(
            //     (x) => (x.value = formData.managerName)
            // )
            // const departmetName = departmentDropDownList.find(
            //     (x) => (x.value = formData.departmentId)
            // )
            // const designationName = designationDropDownList.find(
            //     (x) => (x.value = formData.designationId)
            // )
            // const ClientCatagory = categoryDropDownList.find(
            //     (x) => (x.value = formData.clientCategoryId)
            // )
            const userGroupName =
                formData.userGroupId === 1
                    ? 'Super Admin'
                    : formData.userGroupId === 2
                      ? 'Admin'
                      : formData.userGroupId === 3
                        ? 'Manager'
                        : 'Employee'
            const datatosave = {
                userId: formData.userId,
                firstName: formData.firstName,
                middleName: formData.middleName,
                surName: formData.surName,
                dateOfBirth: formData.dateOfBirth,
                addressOne: formData.addressOne,
                addressTwo: formData.addressTwo,
                town: formData.town,
                country: formData.country,
                postCode: formData.postCode,
                userName: formData.userName,
                imageurl: formData.imageurl,
                emailAddress: formData.emailAddress,
                contactNo: formData.contactNo,
                managerId: formData.managerId,
                managerName: selectedLabelManager,
                workingStatus: 'Acitve',
                userGroupId: formData.userGroupId,
                userGroupName: userGroupName ?? selectedLabelUsergroup,
                clientCategoryId: formData.clientCategoryId,
                clientCategoryName: selectedLabelcategory,
                departmentId: formData.departmentId,
                departmentName: depatmetlable ?? '',
                designationId: formData.designationId,
                designationName: selectedLabelDesignation,
                isActive: '',
                isDelete: '',
                isActivated: editData.isActivated,
            }
            console.log('formData', formData)
            void saveUserInfoData(datatosave)
        } catch (error) {}
    }
    const saveUserInfoData = async (
        data: ManageUserDetailsFormDataInterface
    ): Promise<void> => {
        await SaveUserInfo(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    onSave(resetForm)
                }
            })
            .catch(() => {})
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<ManageUserDetailsFormDataInterface>
                    name="userId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Group & Partners"
                            name="clientCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your Group & Partners!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={handleCategoryChange}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...categoryDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Department"
                            name="departmentId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Department!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handleDepartmentChange}
                                options={[
                                    { label: 'Please select', value: null },
                                    ...departmentDropDownList,
                                ]}
                                showSearch
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Designation"
                            name="designationId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Designation!',
                                },
                            ]}
                        >
                            <Select
                                onChange={handleDesignationchanage}
                                allowClear
                                placeholder="Please select"
                                options={[
                                    { label: 'Please select', value: null },
                                    ...designationDropDownList,
                                ]}
                                showSearch
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Middle Name"
                            name="middleName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Surname"
                            name="surName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Surname!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Date of Birth"
                            name="dateOfBirth"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Date of Birth!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate?.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Address One"
                            name="addressOne"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Address One!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Address Two"
                            name="addressTwo"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Town"
                            name="town"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your town!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Country"
                            name="country"
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={countryList?.map((country) => ({
                                    value: country.label,
                                    label: country.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="PostCode"
                            name="postCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your PostCode!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Email"
                            name="emailAddress"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Nick Name"
                            name="userName"
                            rules={[
                                {
                                    required: true,
                                    message: 'please enter Nick Name',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Contact No"
                            name="contactNo"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Please enter a valid mobile number (10 to 15 digits).',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Reporting Person"
                            name="managerId"
                            rules={[
                                {
                                    required: true,
                                    message: 'please enterReporting Person',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={handleManagerChange}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={userList}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="User Group"
                            name="userGroupId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please InputUser Group!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handleUserGroupChange}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' &&
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option value={0}>
                                    Please select
                                </Select.Option>
                                <Select.Option value={1}>
                                    Super Admin
                                </Select.Option>
                                <Select.Option value={2}>Admin</Select.Option>
                                <Select.Option value={3}>Manager</Select.Option>
                                <Select.Option value={4}>
                                    Employee
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ManageUserDetailsFormDataInterface>
                            label="Image"
                            name="imageurl"
                        >
                            <S3Upload
                                name={'imageurl'}
                                value={form.getFieldValue('imageurl')}
                                setFormData={setFormData}
                                corporate={''}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('9000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button
                                type="primary"
                                onClick={saveuserInformationData}
                            >
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): UserINFOFormStateInterface => {
    return {
        countryList: state.common.countryList,
        userList: state.user.userList,
        categoryDropDownList: state.initial.categoryDropDownList,
        departmentDropDownList: state.userrights.departmentDropDownList,
        designationDropDownList: state.userrights.designationDropDownList,
    }
}

export default connect(mapStateToProps)(UserInfoForm)
